/* ================================================================
   News
   ================================================================ */

.news-listing {
    ul.item-list .item + .item { margin-top:16px; border-top:0; }
    ul.item-list .item {
        .summary { margin:0; font-style: none; font-weight: $fontWeight-normal; }
    }

    @media(min-width:$bp-m) {
        ul.item-list {
            display: flex;
            flex-wrap:wrap;
            margin:0;
            margin-left:-$grid-gutter-em;
            margin-right:-$grid-gutter-em;

            .item {
                width:50%;
                margin:0 0 1em;
                padding:0 $grid-gutter-em;
            }
        }    
        ul.item-list .item + .item { margin-top:0; border-top:0; }
    }

    @media(min-width:$bp-l) {
        ul.item-list .item { width:25%; }
    }
}

.news-details .title { margin:1em 0; color:$color-purple; }

// Press Releases

.press-release {

}
.press-release__item-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}
.press-release__item {
    width: 100%;
    margin: 0 0 20px;
}
.press-release__title {
    font-size: 1rem;
    margin: 0 0 1em;
}
.press-release__link {
    display: block;
}
.press-release__detail { 
    font-size: .8125rem;
    margin: 0 0 .5em;
}

@media (min-width: 60em) {
    .press-release__item {
        width: 50%;
        padding: 0 0 0 90px;
    }
}