.rect-callout__media {
    border-radius: 8px;
    margin-bottom:12px;
    overflow: hidden;
    background:$color-white;
}

.rect-callout__title {
    font-size:clamp(1.2rem, 1.3vw, 1.625rem);
    line-height: 1.2;
}

.rect-callout__subtitle {
    font-size:clamp(1rem, 1.04vw, 1.25rem);
    line-height: 1.7;
}


.rect-callout__action {
    margin-top:8px;
}

.rect-callout-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap:clamp(16px, 1.25vw, 24px);

    .rect-callout {
        display:flex;
        flex-direction:column;
        gap:8px;
    }

    .rect-callout__media {
        margin-bottom:4px;
    }

    .rect-callout__title {
        margin-bottom:8px;
    }

    .rect-callout__action {
        margin-top:auto;
    }

    &.tns-slider {
        padding-block:1px;
        flex-wrap: nowrap;

        .rect-callout {
            display: flex;
        }
    }
}

.rect-callout-slider__nav {
    display: flex;
    align-items: center;
    gap:24px;
    margin-top:24px;
    color:$color-black;

    .dark-gray-bg & {
        color:$color-white;
    } 
}

.rect-callout-slider__prev,
.rect-callout-slider__next {
    @extend %buttonreset;
    display: flex;
    align-items: center;
    justify-content: center;
    width:40px;
    height:40px;
    border-radius:50%;
    border: 2px solid transparent;
    background:#e9ecf0;
    transition:background .3s ease-in-out, border-color .3s ease-in-out;
    color:$color-base;

    svg {
        fill:currentColor;

        .dark-gray-bg & {
            fill:$color-black;
        }
    }

    &[disabled] {
        border-color:currentColor;
        background:transparent;
        color:currentColor;

        .dark-gray-bg & {
            svg {
                fill:$color-white;
            }
        }
    }

}

.rect-callout__pager {
    display: flex;
    align-items: center;
    gap:16px;
}

.rect-callout__pager-item {
    @extend %buttonreset;
    border-radius: 50%;
    width:12px;
    height: 12px;
    border:1px solid $color-base;
    transition:background .3s ease-in-out;

    &.tns-nav-active {
        background:$color-base;
    }

    .dark-gray-bg & {
        border-color:$color-white;

        &.tns-nav-active {
            background:$color-white;
        }
    }
}

@media(min-width:$bp-m){
    .rect-callout {
        text-align: center;
    }

    .rect-callout-list .rect-callout {
        width:32%;
    }

    .rect-callout-slider__nav {
        display: none;
    }
}

@media(min-width:$bp-l){
    .rect-callout__media {
        border-radius: 16px;
    }
}