/* ================================================================
    Button Styles
   ================================================================ */
button {
    cursor: pointer;
}

.button,
a.button {
    --link-color:#{$color-base};
    --button-bg-color:#{$button-color};
    position: relative;
    display: inline-flex;
    gap:10px;
    justify-content: center;
    max-width: 100%;
    border: 0;
    border-radius: 80px;
    padding: 12px clamp(1.25rem, 2.29vw, 2.75rem);
    line-height: 1.5;
    font-size:clamp(0.875rem, .93vw, 1rem);
    font-family: $font-primary;
    font-weight: $fontWeight-bold;
    text-decoration: none;
    text-align: center;
    background: var(--button-bg-color);
    color: var(--link-color);
    transition:background .3s ease-in-out;
    cursor: pointer;

    &:hover,
    &:focus {
        text-decoration: none;
        background: #51afe4;
    }

    img {
        display: inline-block;
        vertical-align: middle;
    }

    svg {
        fill: $color-white;
    }

    &.button-block {
        display: block;
        width: 100%;
    }

    &.button-small {
        padding: .4em .8em;
    }

    &.button-large {
        padding: 1em 4em;
    }

    &.button-wide {
        padding: .8em 4em;
    }

    &.button--w100 {
        width: 100%;
    }
}

// White Button Styles accounts for aligning image vertically with text and doesn't allow text to wrap underneath.
.button.button-white,
a.button.button-white {
    --button-bg-color:#{transparentize($color-white, .1)};
    background: transparentize($color-white, .1);

    &:hover,
    &:focus-visible,
    &:active {
        background: #f0f0f0;
    }
}

// Purple Button Styles
.button.button-purple,
a.button.button-purple {
    --link-color:#{$color-white};
    --button-bg-color:#{$color-purple};
    background: $color-purple;
    color:$color-white;

    &:before {
        background: $color-purple;
    }

    &:after {
        border-left-color: $color-purple;
    }

    &:hover,
    &:focus-visible,
    &:active {
        background: darken($color-purple, 15%);
    }
}

.button.button--ghost,
a.button.button--ghost {
    --button-bg-color:transparent;
    border:1px solid $color-base;
    background:transparent;

    &:hover, 
    &:focus-visible {
        background:#f0f0f0;
    }
}

.button.button--ghost-white,
a.button.button--ghost-white {
    --link-color:#{$color-white};
    --button-bg-color:transparent;
    border:1px solid $color-white;
    background:transparent;
    color:$color-white;
    transition:background .3s ease-in-out, color .3s ease-in-out;

    &:hover,
    &:focus-visible {
        background:#f0f0f0;
        color:$color-base;
    }
}

.button.button--yellow,
a.button.button--yellow {
    --button-bg-color:#{$color-yellow};
    background:$color-yellow;

    &:hover,
    &:focus-visible {
        background:#f2ca19;
    }
}

.button.button--secondary,
a.button.button--secondary {
    --button-bg-color:#{$color-secondary};
    background:$color-secondary;
}