/* ================================================================
   Staff Module Styling
   ================================================================ */

   .staff-listing {
       .item-list {
           .item {
               position:relative;

               .inner {
                   position: relative;
                   width: calc(100% - 30px);
                    padding: 30px 0 30px 30px;
                    background: $color-gray-3;
                    &:before, &:after {
                        content: '';
                        position: absolute;
                        display: block;
                        right: -30px;
                    }
                    &:before {
                        top: 0;
                        width: 30px;
                        height: calc(100% - 30px);
                        background: $color-gray-3;
                    }
                    &:after {
                        bottom: 0;
                        width: 30px; height: 0;
                        border-left: 30px solid $color-gray-3;
                        border-bottom: 30px solid rgba(0, 0, 0, 0);
                    }
               }

               .media {
                   overflow: hidden;
                   img {
                       width:100%;
                       transform: none;
                       transition:transform 0.8s ease-in-out;
                   }
                   a {
                       display: block;
                       &:hover img { transform: scale(1.1); }
                   }
                }

               .title {
                   margin:0 0 10px;
                   font-size: 1.5rem;
                   font-weight: $fontWeight-medium;
                   line-height: normal;
                   color:$color-black;
                   a {
                       color:$color-black;
                       &:hover, &:focus { text-decoration: none; color:$color-primary; }
                   }
               }
           }
           .item + .item { margin-top:1.5em; padding:0; border:0; }

           @media(min-width:$bp-m){
               display: flex;
               flex-wrap:wrap;
               margin:0;
               margin-left:-$grid-gutter-em;
               margin-right:-$grid-gutter-em;
               
               .item {
                   width:50%;
                   padding:0 $grid-gutter-em !important;
                   margin:0 0 1em !important;

                   .media {
                       position:relative;
                       height:300px;
                       overflow:hidden;

                       img {
                           position:absolute;
                           top:0; left:0;
                           min-height:100%;
                           object-fit:cover;
                       }
                   }
               }
           }

           @media(min-width:$bp-l){
               .item { width:33.3333%; }
           }
       }
   }