@charset "UTF-8";

// Default Variables

$slick-font-path: "/ResourcePackages/Talon/assets/dist/fonts/slick/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "/ResourcePackages/Talon/assets/dist/images/" !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-dot-character: "•" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }

    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }

    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}


/* Arrows */

.slick-arrow {
    position: absolute;
    top: 50%;
    z-index: 10;
    width: 45px;
    height: 65px;
    padding: 0;
    border-color: transparent;
    background: none;
    overflow: hidden;
    cursor: pointer;
    color: transparent;

    &:before {
        content: "";
        position: absolute;
        top: 15px;
        width: 40px;
        height: 40px;
        border-radius: 3px;
        transition: border-color 0.3s ease-in-out;
    }

    &.slick-prev {
        left: 0;

        &:before {
            left: 15px;
            border-left: 4px solid $color-white;
            border-bottom: 4px solid $color-white;
            transform: rotate(45deg);
        }
    }

    &.slick-next {
        right: 0;

        &:before {
            right: 15px;
            border-right: 4px solid $color-white;
            border-top: 4px solid $color-white;
            transform: rotate(45deg);
        }
    }

    &:hover,&:focus {
        background: transparent;
        color: transparent;

        &:before {
            border-color: $color-purple;
        }
    }
}

.slick-prev {
    left: 0;

    [dir="rtl"] & {
        left: auto;
        right: 0;
    }

}

.slick-next {
    right: 0;

    [dir="rtl"] & {
        left: 0;
        right: auto;
    }
}

/* Dots */

.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -45px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    width: 100%;

    li {
        display: inline-block;
        vertical-align: middle;
        padding: 0 5px;

        button {
            width: 12px;
            height: 12px;
            margin: 0;
            padding: 0;
            border-color: transparent;
            border-radius: 50%;
            border: 1px solid $color-purple;
            background: $color-white;
            overflow: hidden;
            cursor: pointer;
            color: transparent;
            will-change: background;
            transition: background 0.3s ease-in-out;

            &:hover {
                background: $color-purple;
            }
            &:focus-visible {
                box-shadow: 0 0 1px 1px $color-white, 0 0 2px 2px $color-primary;
            }
        }

        &.slick-active button {
            background: $color-purple;
            border-color: $color-purple;
        }
    }
}