.site-header {
    position: fixed;
    left:clamp(8px, .83vw, 16px);
    right:clamp(8px, .83vw, 16px);
    z-index: 1000;
    border-radius: 12px 12px 0px 0px;
    padding: 24px;
    background: rgba(0,0,0,.4);
    backdrop-filter: blur(32px);
    color:$color-white;

    a {
        color:currentColor;
    }
}

.header-layout {
    display: flex;
    justify-content: space-between;
    max-width:93.75em;
    margin:0 auto;
}

.header-col.header-col--logo {
    flex-shrink:0;

    img {
        width:clamp(110px, 10vw, 200px)
    }
}

.header-col.header-col--nav {
    position:absolute;
    top:100%;
    left:0;
    display: none;
    flex-direction: column;
    gap:24px;
    width:100%;
    padding:24px;
    opacity:0;
    background:$color-white;
    transition:opacity .3s ease-in-out;
    color:$color-base;
    overflow: auto;

    &.active {
        display:flex;
    }

    &.animate {
        opacity:1;
    }

    .header-utility {
        order:2;
    }

    @media(orientation:landscape) and (max-height: 47.9375rem){
        height:calc(100vh - 100px);
        overflow: auto;
    }
}

.header-utility {
    display: flex;
    align-items: center;
    justify-content: center;
    gap:20px;
    border-top:1px solid $color-base;
    padding-top:24px;

    & > * + * {
        padding-left:20px;
        border-left:1px solid $color-base;
    }

    .sf-contentblock ul {
        @extend %listreset;
        display: flex;
        align-items: center;
        gap:20px;

        li + li {
            padding-left:20px;
            border-left:1px solid $color-base;
        }

        a {
            text-decoration: none;
    
            &:hover, &:focus-visible {
                text-decoration: underline;
            }
        }
    }
}

.search-toggle,
.main-nav-toggle,
.lang-selector__toggle {
    @extend %buttonreset;
    line-height: 0;
}

.search-toggle.search-toggle--desktop {
    display: none;
}

.search-toggle__icon,
.main-nav-toggle__icon {
    width:24px;
    height:24px;
    fill:$color-white;
}

.site-search {
    display: none;
}

.lang-selector {
    position:relative;
    color:$color-base;
}

.lang-selector__toggle {
    color:currentColor;

    .icon {
        fill:currentColor
    }
}

.lang-selector__list {
    @extend %listreset;
    position: absolute;
    top:100%;
    right:0;
    z-index:10;
    display: none;
    flex-direction: column;
    gap:10px;
    padding:12px;
    border-radius: 0 0 12px 12px;
    background:$color-white;
    white-space: nowrap;
}

.lang-selector__lang {
    @extend %buttonreset;
    text-align: left;
    color:currentColor;
}

.site-search {
    position:relative;
    margin-top:16px;
}

#btnSiteSearch {
    @extend %buttonreset;
    position:absolute;
    top:0;
    right:0;
    padding:0 16px;
    height:100%;

    .icon {
        width:16px;
        height:16px;
    }
}

@media(min-width:$bp-l){
    .site-header {
        padding:32px 24px 16px;
        border-radius: 24px 24px 0px 0px;
        background: rgba(0, 0, 0, 0.60);
        backdrop-filter: blur(44px);
    }

    .header-layout {
        align-items: center;
    }

    .search-toggle,
    .main-nav-toggle {
        display: none;
    }

    .search-toggle.search-toggle--desktop {
        display: block;
    }
    
    .header-utility {
        gap:40px;
        border-top:0;
        padding-top:0;

        & > * + * {
            padding-left:0;
            border-left:0;
        }

        .sf-contentblock ul {
            gap:40px;
    
            li + li {
                padding-left:0;
                border-left:0;
            }
        }
    }

    .lang-selector {
        color:$color-white;
    }

    .lang-selector__list {
        background: rgba(0,0,0,.6);
        backdrop-filter: blur(20px);
    }

    .header-col.header-col--nav {
        position:static;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        width:auto;
        height:auto;
        overflow: visible;
        padding:0;
        opacity:1;
        background:none;
        color:currentColor;

        .header-utility {
            order:-1;
        }
    }
}