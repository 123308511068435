/* ================================================================
   Callouts and Callout Module Styling
   ================================================================ */

   .callout-slider {
       margin:2em 0;

       .slide-item {
           padding:1em;
           
           & > .inner {
                box-shadow:none;
                transform:scale(1);
                transition:all 0.3s ease-in-out;
           }

           .media {
                position: relative;
                height:240px;
                overflow: hidden;

                img {
                    position:absolute;
                    top:0; left:0;
                    width:auto; 
                    min-height:100%;
                    min-width:100%;
                    object-fit: cover;
                }
           }

           .content {
               position:relative;

               &:before, &:after {
                    content:''; 
                    position:absolute; 
                    display:block;
                    right:0;
                    transition:all 0.3s ease-in-out;
                }
                &:before {
                    top:0;
                    width:60px; height:calc(100% - 60px);
                    background:#e7e7e8;
                }
                &:after {
                    bottom:0;
                    width:60px; height:0;
                    border-left:60px solid #e7e7e8;
                    border-bottom:60px solid transparent;
                }

                & > .inner { 
                   width:calc(100% - 60px); 
                   padding:30px 0 30px 30px;
                   background:#e7e7e8;
               }

               .title {
                   margin:0 0 10px;
                   font-size:24px;
                   line-height: normal;
                   font-weight:$fontWeight-semibold;
                   color:$color-black;

                   a {
                       text-decoration: none;
                       color:$color-black;
                       svg { will-change:fill; transition:fill 0.3s ease-in-out; }
                       &:hover, &:focus { 
                           text-decoration: none; 
                           color:$color-primary; 
                           svg { fill:$color-primary; }
                        }
                   }
               }

               p { margin:0; }
           }

           &.slick-cloned { animation:none; opacity:1;  }
       }

       .slick-arrow {
           width:44px; height:44px;
           border-radius:50%;
           background:$button-color;
           font-size:0;
           text-align: center;
           color:$color-white;
           will-change:background;
           transition:background 0.3s ease-in-out;
           &:hover { background:darken($button-color, 15%); }
           &:before { position:static; font-size:20px; border:0; transform:none; }
           &.slick-prev:before { content: "\e900"; }
           &.slick-next:before {  content: "\e901"; }
           &.slick-disabled { background:gray; cursor: initial; }
       }

       @media(max-width:65em) { .slide-item { opacity:1; } }

       @media(min-width:$bp-m) {
           padding:0 90px;
           .slick-arrow { width:70px; height:70px; }
           .slick-arrow:before { font-size:30px; }

           .slide-item .media {
               width:100%;
               height:340px;
                & > a {
                    display: block;
                    position:relative;
                    height:100%;
                    width:100%;
                }
           }
       }

       @media(min-width:64em){
            .slide-item .media { height:240px; }
       }

       @media(min-width:$bp-l){
         .slide-item .inner { box-shadow: 0px -23px 12px 1px rgba(0,0,0,0); }

         .slide-item:hover {
                & > .inner {
                    transform:scale(1.1);
                   box-shadow: 0px -23px 12px 1px rgba(0,0,0,.1);
                }
            }
       }

       @media(min-width:105em) {
           padding:0;
           .slick-arrow.slick-prev { left:-90px; }
           .slick-arrow.slick-next { right:-90px; }
       }

       .no-js & {
           .slide-item img[data-lazy] { display: none; }
           @media(min-width:$bp-m) {
               white-space: nowrap;
               overflow-x: auto;
               .slide-item { display: inline-block; vertical-align: top; white-space: normal; }
           }
       }

       .keyboarder-user & {
           .slick-arrow:focus { outline:2px solid $color-primary; }
           .slick-dots button:focus { outline:2px solid $color-primary; }
       }
   }
   
   .callout {
       padding:1em;
           
        & > .inner {
            box-shadow:none;
            transform:scale(1);
            transition:all 0.3s ease-in-out;
        }

        .media img { width:100%; }

        .content {
            position:relative;

            &:before, &:after {
                content:''; 
                position:absolute; 
                display:block;
                right:0;
                transition:all 0.3s ease-in-out;
            }
            &:before {
                top:0;
                width:60px; height:calc(100% - 60px);
                background:#e7e7e8;
            }
            &:after {
                bottom:0;
                width:60px; height:0;
                border-left:60px solid #e7e7e8;
                border-bottom:60px solid transparent;
            }

            & > .inner { 
                width:calc(100% - 60px); 
                padding:30px 0 30px 30px;
                background:#e7e7e8;
            }

            .title {
                margin:0 0 10px;
                font-size:24px;
                line-height: normal;
                font-weight:$fontWeight-semibold;
                color:$color-black;

                a {
                    text-decoration: none;
                    color:$color-black;
                    svg { will-change:fill; transition:fill 0.3s ease-in-out; }
                    &:hover, &:focus { 
                        text-decoration: none; 
                        color:$color-primary; 
                        svg { fill:$color-primary; }
                    }
                }
            }

            p { margin:0; }
        }
   }