/* ==========================================================================
   FAQs
   ========================================================================== */

.faq-legend {
  display:inline-block;
  padding:calc($grid-gutter/2) $grid-gutter; margin-bottom:2em;
  border:1px solid #CCCCCC;
  background:#F2F2F2;

  ol { margin:0; padding:0 0.5em 0 15px; }

  li {
    list-style-type: square;

    + li { margin-top:0.2em; }

    a {
      text-decoration:none;
      &:hover, &:focus { text-decoration:underline; }
    }
  } 
}

.faq-details {
  .module-list .item + .item { margin-top:$grid-gutter; }

  .item {
    .title { padding-right:40px; }

    .top {
      position:absolute; top:5px; right:0;
      @include x-rem(font-size, 10px);
      text-transform:uppercase; text-decoration:none;
      color:#CCCCCC;

      &:hover, &:focus { text-decoration:underline; }

      &:before {
        content:'';
        position:absolute; right:100%; top:4px;
        margin-right:4px;
        width:0; height:0;
        border-style:solid; border-width:0 5px 5px 5px; border-color:transparent transparent #CCCCCC transparent;
      }
    }
  }
}