/* ================================================================
   Hero Module
   ================================================================ */
.hero {
    position: relative;
    z-index: 1;
    background: #2f2c33;
    overflow: hidden;
}

.hero__content {
    position: relative;
    z-index: 5;
    padding: 5% 0;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(47, 44, 51, .3);
    }
}

.hero__media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}

.hero__title {
    position: relative;
    margin: 0 0 30px;
    font-size: 2.25rem;
    line-height: 36px;
    font-weight: $fontWeight-light;
    color: $color-white;
}

.hero__action-buttons {
    @extend .list-unstyled;
}

.hero__action-item {
    margin: 0 0 10px;
}

// 768px
@media(min-width:$bp-m) {
    .hero__media {
        background-size: auto 100%;
        background-position: top right;
    }

    .hero__content {
        position: relative;
        padding: 9.2% 0;

        &::before {
            width: 70%;
            background: linear-gradient(to right, #2f2c33 0%, #2f2c33 60%, rgba(47, 44, 51, 0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2f2c33', endColorstr='#002f2c33', GradientType=1);
        }
    }

    .hero__action-buttons {
        display: flex;
        width: 100%;
        align-items: center;
    }

    .hero__action-item {
        margin: 0 1em 0 0;
    }
}


// 1024px+
@media(min-width:$bp-mw) {
    .hero__title {
        font-size: 3rem;
        line-height: 1;
    }
}

// 768px - 1139px
@media(min-width:$bp-m) and (max-width:$bp-mw-max) {
    .hero__content {
        padding: 9.2% 5%;
    }

    .hero__action-item {
        flex-grow: 1;
    }
}

// 1140px+
@media(min-width:$bp-l) {
    .hero__title {
        max-width: 70%;
        margin: 0 0 60px;
        font-size: 4.375rem;
        line-height: 1.14;
    }
}

//icon-row content-block area
.icon-row {
    h1 {
        margin-left: 0 !important;
        display: flex;
        align-items: center;
        line-height: 1em;
    }

    ul {
        margin-left: 0 !important;
    }
}


// Hero Slider
.hero-carousel {
    position: relative;
}
.hero-carousel__slider {
    margin: 0;
    .hero__content {
        min-height: 500px;
    }
    .slick-dots {
        bottom: 25px;

        li {
            button {
                width: 20px;
                height: 20px;

                &:before {
                    content: '';
                }
            }
        }
    }
}
.hero-carousel__control {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background: none;
    border: 0;
    z-index: 10;
    background: $color-purple;
    &::before,
    &::after {
        content: '';
        width: 3px;
        height: 24px;
        position: absolute;
        background: $color-white;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    &::after {
        left: auto;
        right: 10px;
    }

    &[aria-pressed="true"] {
        &::before {
            display: none;
        }

        &::after {
            left: 0;
            right: 0;
            margin: auto;
            background: none;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 12px 0 12px 24px;
            border-color: transparent transparent transparent $color-white;
        }
    }
}

//1140px
@media (min-width: $bp-l) {
    .hero-carousel {
        .hero__content {
            height: 575px;
        }
    
        .slick-arrow {
            top: 80%;
        }
    }
    .hero-carousel__control {
        right: 75px;
    }
}