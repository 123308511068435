.circle-img-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:clamp(6px, 1.25vw, 24px);
}

.circle-img-card__media {
    position: relative;
    max-width:256px;
    max-height:256px;
    border-radius: 50%;
    overflow: hidden;

    img {
        border-radius: 50%;
    }
}

.circle-img-card__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:clamp(6px, 1.25vw, 24px);
    text-decoration: none;
    color:$color-base;

    .circle-img-card__media:before {
        @include pseudo();
        width:100%;
        height:100%;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.70) url($image-path+"logo-minimal-small.png") no-repeat center center;
        opacity: 0;
        transition:opacity .3s ease-in-out;
    }
    .circle-img-card__media:after {
        @include pseudo(block, absolute, 'Learn More');
        top:70%;
        left:50%;
        transform:translate(-50%, -70%);
        font-weight:$fontWeight-bold;
        color:$color-white;
        opacity: 0;
        transition:opacity .3s ease-in-out;
    }

    &:hover,
    &:focus-visible {
        .circle-img-card__media:before,
        .circle-img-card__media:after {
            opacity: 1;
        }
    }
}

.circle-img-card__title {
    font-size:clamp(0.875rem, 1.04vw, 1.25rem);
    font-weight:$fontWeight-medium;
    line-height:1.15;
}

.circle-img-card-list {
    display: flex;
    flex-wrap: wrap;
    column-gap:32px;
    row-gap:16px;
    justify-content: center;

    .circle-img-card {
        width:44%;
    }
}

@media(min-width:$bp-l){
    .circle-img-card-list {
        .circle-img-card {
            width:auto;
        }
    }
}