.callout {
    padding: 1em;
}

.callout__inner {
    box-shadow: none;
    transform: scale(1);
    transition: all 0.3s ease-in-out;
}

.callout__inner--secondary {
    width: calc(100% - 60px);
    padding: 30px 0 30px 30px;
    background: $color-gray-3;
}

.callout__image {
    width: 100%;
}

.callout__content {
    position: relative;

    &:before,
    &:after {
        content: '';
        position: absolute;
        display: block;
        right: 0;
        transition: all 0.3s ease-in-out;
    }

    &:before {
        top: 0;
        width: 60px;
        height: calc(100% - 60px);
        background: $color-gray-3;
    }

    &:after {
        bottom: 0;
        width: 60px;
        height: 0;
        border-left: 60px solid $color-gray-3;
        border-bottom: 60px solid transparent;
    }
}

.callout__title {
    margin: 0 0 10px;
    font-size: 1.5rem;
    line-height: normal;
    font-weight: $fontWeight-semibold;
    color: $color-black;
}

.callout__summary {
    margin: 0;
}