/* ================================================================
   Alerts
   ================================================================ */

$alert-info-bg: #ffd401;
$alert-info-color: #666f80;
$alert-emergency-bg: #cc0001;
$alert-emergency-color: #fff;

.alert-mod { margin: 0; padding-top: $grid-gutter; }

.alert-list {
	display: block;
	margin: 0 0 $grid-gutter; padding: 0;

	li {
		position: relative;
		display: block;
		margin: 0 0 calc($grid-gutter/2);
	}
}

.alert {
	position: relative;
	padding: $grid-gutter $grid-gutter;

	//@include breakpoint(m) { padding: $grid-gutter $grid-gutter * 4; }

	.title {
		display: inline;
		margin: 0; padding-right: 0.5em;
		text-transform: uppercase;
		font-weight: $fontWeight-bold; @include x-rem(font-size, 16px);

		&:after { content: ':'; }

		a {
			text-decoration: none;

			&:hover, &:focus { text-decoration: underline; }
		}
	}

	.desc {
		display: inline;
		margin: 0; padding-right: 0.5em;
		@include x-rem(font-size, 16px);
	}

	p { margin: 0; }

	.more {
		display: inline-block;
		padding-left: 0.7em;
		border-left: 2px solid #fff;
		@include x-rem(font-size, 14px);
		@include x-rem(line-height, 16px);
		text-decoration: none; text-transform: uppercase;

		&:hover, &:focus { text-decoration: underline; }
	}

	&:before {
		position: absolute; left: 0.6em; top: 0.3em;
		@include x-rem(font-size, 32px);		
	}

	&.alert-Emergency {
		background: $alert-emergency-bg;
		color: $alert-emergency-color;

		&:before { 
			//@extend .icons-warning; 
		}

		//@include breakpoint(m) {
		//	&:before { content: '';	}
		//}

		.title, a { color: $alert-emergency-color; }

		.more { border-left-color: $alert-emergency-color; }
	}

	&.alert-Information {
		background: $alert-info-bg; 
		color: $alert-info-color; 

		a { color: $alert-info-color; }

		.more { border-left-color: $alert-info-color; }
	}
}
