.hero-card {
    position:relative;
    z-index: 1;
    border-radius: 12px;
    margin-bottom:8px;
    padding:clamp(120px, 12.9vw, 248px) 16px clamp(40px, 12.9vw, 248px);
    background-size:cover;
    background-repeat: no-repeat;
    overflow: hidden;

    &:before {
        @include pseudo();
        top:0;
        left:0;
        width:100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%) no-repeat;
        z-index:-1;
    }
}

.hero-card__media {
    position:absolute;
    top:0;
    left:0;
    z-index:-2;
    width:100%;
    height:100%;
    background-size:cover;
}

.hero-card__content-wrap {
    max-width:93.75rem;
    margin:0 auto;
}

.hero-card__content {
    position: relative;
    max-width:610px;

    > * {
        margin:0;
        font-family: $font-heading;
        font-size: clamp(48px, 3.75vw, 4.5rem);
        font-weight: $fontWeight-light;
        line-height: clamp(1.3em,  1.4vw, 1.7em);
        color:$color-white;
    }
}

@media(min-width:$bp-l){
    .hero-card {
        border-radius: 24px;

        &:before {
            background:linear-gradient(90deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.10) 100%), linear-gradient(90deg, #FFF 36.69%, rgba(255, 255, 255, 0.00) 49.06%) no-repeat;
        }
    }

    .hero-card__media {
        width:75%;
        left:auto;
        right:0;
    }

    .hero-card + .tns-outer,
    .hero-card + .text-callout-list {
        position:relative;
        z-index:2;
        margin-top:-100px;
    }
}