
// Location Listing
.location-listing {

}
.location-listing__item {
	& + .location-listing__item {
		margin: 25px 0 0;
	}
}
.location-listing__media {
	margin: 0 0 1em;
}
.location-listing__content {
	margin: 0 0 .5em;
}
.location-listing__title {
        margin: 0 0 15px;
        font-size: 1.5rem;
        font-weight: normal;
        line-height: normal;
        color: $color-purple;
}
.location-listing__inner {

}
.location-listing__info {

}
.location-listing__heading {
	margin: 0;
	font-size: 1rem;
}
.location-listing__label {
	display: inline-block;
	font-weight: $fontWeight-bold;
}
.location-listing__address {
	margin: 0;
	padding: 0;
	font-style: normal;
}
.location-listing__value {
	display: inline-block;
}
.location-listing__value--block {
	display: block;
}

@media (min-width: $bp-mw) {
	.location-listing__item {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
	.location-listing__media {
		width: 250px;
	}
	.location-listing__image {
		width: 100%;
	}
	.location-listing__content {
		display: flex;
		flex-wrap: wrap;
		width: calc(100% - 250px);
		padding: 0 0 0 60px;
	}
	.location-listing__title {
		width: 100%;
	}
	.location-listing__column {
		padding: 0 60px 0 0;
		width: 200px;
	}
	.location-listing__column--secondary {
		width: calc(100% - 200px);
	}
}

@media (min-width: $bp-l) {
	.location-listing__item {
		& + .location-listing__item {
			margin: 50px 0 0;
		}
	}
}