.location-grid {}

.location-grid__list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.location-grid__item {
    margin-bottom: 4rem;
    position: relative;

    &:after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: #ccc;
        position: absolute;
        bottom: -3rem;
        left: 0;
    }

    &:nth-last-child(1) {
        &:after {
            content: none;
        }
    }
}

.location-grid__content {}

.location-grid__title {
    font-size: 1.5rem;
    font-family: "Raleway", sans-serif;
    margin-bottom: 1rem;
    font-weight: 400;
    color: $color-purple;
}

.location-grid__inner {}

.location-grid__address {
    font-style: normal;
    margin-bottom: 1rem;
}

.location-grid__info {}

.location-grid__contact {
    margin: 0 0 1rem;
}

@media (min-width: $bp-mw) {
    .location-grid__list {
        display: flex;
        flex-wrap: wrap;
    }

    .location-grid__item {
        display: flex;
        width: 50%;
        float: left;

        &:nth-child(odd) {
            padding-right: $grid-gutter-em;
        }

        &:nth-child(even) {
            padding-left: $grid-gutter-em;
        }

        &:nth-last-child(2) {
            &:after {
                content: none;
            }
        }
    }

    .location-grid__content {
        width: 75%;
    }

    .location-grid__media {
        width: 25%;
        padding: 0 .5rem;
        margin-top: 0;
    }
}

@media (min-width: $bp-l) {
    .location-grid__item {
        width: 33.333336%;

        &:nth-child(3n+1) {
            padding-right: $grid-gutter-em;
        }

        &:nth-child(3n+2) {
            padding-right: $grid-gutter-em;
        }

        &:nth-child(3n+3) {
            padding: 0;
        }

        &:nth-child(even) {
            padding-left: 0;
        }

        &:nth-last-child(3) {
            &:after {
                content: none;
            }
        }

    }

}