.text-callout {
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    padding:clamp(30px, 2.5vw, 48px) clamp(16px, 2.1vw, 40px);
    text-align: center;

    &.tns-item {
        display: flex !important;
    }
}

.text-callout__subtitle {
    font-size:clamp(1rem, 1.04vw, 1.25rem);
}

.text-callout__title {
    margin:0 0 clamp(8px, .83vw, 16px);
    font-size:clamp(1.125rem, 1.35vw, 1.625rem);
    font-weight:$fontWeight-normal;
    color:currentColor;
}

.text-callout__subtext {
    margin:0 0 clamp(8px, 1.25vw, 24px);
    font-size:0.875rem;
}

.text-callout__action {
    margin-top:auto;
}

.text-callout.text-callout--lightblue {
    background: radial-gradient(106.9% 43.9% at 89.38% 106.46%, rgba(62, 166, 225, 0.20) 0%, rgba(62, 166, 225, 0.00) 100%), 
        radial-gradient(190.94% 115.91% at 19.94% -11.95%, rgba(62, 166, 225, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), 
        var(--Neutral-Neutral-4, #FFF);
    box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.10), 0px 4px 10px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px rgba(255, 255, 255, 0.25) inset;
    backdrop-filter: blur(44px);
    color:$color-base;

    .text-callout__subtitle {
        color:$color-blue;
    }
}

.text-callout.text-callout--blue {
    background: radial-gradient(106.9% 43.9% at 89.38% 106.46%, rgba(151, 71, 255, 0.30) 0%, rgba(151, 71, 255, 0.00) 100%), 
        radial-gradient(190.94% 115.91% at 19.94% -11.95%, rgba(71, 244, 255, 0.30) 0%, rgba(151, 71, 255, 0.00) 100%), 
        var(--Primary-Primary-Compliant, #187DB7);
    box-shadow: 0px 4px 4px -1px rgba(0, 0, 0, 0.15), 0px 8px 16px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px rgba(255, 255, 255, 0.25) inset;
    color:$color-white;

    .text-callout__subtitle {
        color:$color-yellow;
    }
}

.text-callout-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap:24px;
    max-width:$grid-container-width;
    margin-left:auto;
    margin-right:auto;

    &.tns-slider {
        flex-wrap: nowrap;
        max-width:none;
        margin-left:0;
        margin-right: 0;
        padding-block:1px;
    }
}


.text-callout-slider__nav {
    display: flex;
    align-items: center;
    gap:24px;
    margin-top:16px;
}

.text-callout-slider__prev,
.text-callout-slider__next {
    @extend %buttonreset;
    display: flex;
    align-items: center;
    justify-content: center;
    width:40px;
    height:40px;
    border-radius:50%;
    border: 2px solid transparent;
    background:$color-black;
    transition:background .3s ease-in-out, border-color .3s ease-in-out;
    color:#e9ecf0;

    svg {
        fill:currentColor;
    }

    &[disabled] {
        border-color:currentColor;
        background:transparent;
        color:$color-black;
    }

}

.text-callout__pager {
    display: flex;
    align-items: center;
    gap:16px;
}

.text-callout__pager-item {
    @extend %buttonreset;
    border-radius: 50%;
    width:12px;
    height: 12px;
    border:1px solid $color-black;
    transition:background .3s ease-in-out;

    &.tns-nav-active {
        background:$color-black;
    }
}

@media(min-width:37.5em){
    .text-callout-list {
        display:grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .text-callout-slider__nav {
        display: none;
    }
}

@media(min-width:$bp-m){
    .text-callout-list {
        display: flex;
    }
    
    .text-callout-list .text-callout {
        width:40%;
    }
}