.sf-list--expandable {
	i { margin-right: 5px; }

	.sf-list-content { margin-left: 20px; }

	.sf-list-item {
		> div { margin-bottom: 5px; }

		h3:first-child { margin-top: 0; }
	}
}