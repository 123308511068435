/* ================================================================
   Parametric Search Styles
   ================================================================ */


.parametric-search {
    .dataTables_filter {
        display: none;
    }
}

.primary-filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.primary-filters__group {
    width: 100%;
    margin: 0 0 15px;
}

.primary-filters__label {
    display: block;
    font-weight: $fontWeight-semibold;
    margin: 0 0 .5em;
}

.primary-filters__select {
    height: 55px;
}

.filter-parameters {
    margin: 2em 0;
}

.filter-parameters__legend {
    margin: 0;
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-bottom: 0;
    background: #eee;
    font-size: 1rem;
    line-height: normal;
    color: $color-purple;
}

.filter-parameters__list {
    max-height: 300px;
    overflow-y: auto;
    margin-top: 0;
    padding: 20px;
    border: 1px solid #ccc;
    list-style-type: none;
}

.filter-parameters__item {
    position: relative;
}

.filter-parameters__item+.filter-parameters__item {
    margin: 10px 0 0;
}

.filter-parameters__checkbox {
    @extend .visually-hidden;

    &:focus-visible+label {
        &::before {
            box-shadow: 0 0 1px 1px $color-primary, 0 0 2px 2px $color-purple;
        }
    }
}

.filter-parameters__checkbox:checked+label {
    color: $color-primary;

    &:before {
        border-color: $color-primary;
        background: $color-primary;
    }
}

.filter-parameters__label {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    transition: color 0.3s ease-in-out;

    &:before {
        content: "";
        position: absolute;
        top: 4px;
        left: 0;
        width: 13px;
        height: 13px;
        border: 1px solid #ccc;
        background: $color-white;
        transition: all 0.3s ease-in-out;
    }
}

// loading indicator 
.parametric-search__loading {
    display: none;
    position: relative;
    width: 80px;
    height: 80px;
	&.active {
		display: inline-block;
	}
}

.parametric-search__loading div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: $color-primary;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.parametric-search__loading div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}

.parametric-search__loading div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}

.parametric-search__loading div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
}

.parametric-results__header {
    margin: 1em;
}

@keyframes lds-facebook {
    0% {
        top: 8px;
        height: 64px;
    }

    50%,
    100% {
        top: 24px;
        height: 32px;
    }
}



@media(min-width:$bp-m) {
    .filter-parameters {
        display: flex;
        flex-wrap: wrap;
        margin: 2em -1em;

    }

    .filter-parameter-group {
        width: 33.333%;
        padding: 0 1em;
    }
}


@media (min-width: $bp-mw) {
    .primary-filters__group {
        width: calc(100% / 3);
        padding: 0 $grid-gutter-em;
    }

    .primary-filters__submit {
        margin: 30px 0 0;
    }
}