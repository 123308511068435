/* ================================================================
   Events
   ================================================================ */

.event-banner {
  img { width: 100%; }
}

.event-social.contentBox {
  padding: 0;

  .media { display: none; }

  .content {
    position: static; 
    padding-bottom: 36px;
  }
}

.event-detail {
  position: relative; 
  overflow: hidden;
  min-height: 150px;
  //margin: (-$grid-gutter) (-$grid-gutter) $grid-gutter;
  padding: $grid-gutter;
  @include x-rem(font-size, 13px); font-family: $font-primary; color: #fff; 
  background: #6a747c url(../Images/layout/events-details-bg.png) 0 0 no-repeat;

  //@include breakpoint(m) {
  //  padding: $grid-gutter $grid-gutter $grid-gutter*2 110px;
  //}

  ul, li {
    display: block;
    margin: 0; padding: 0;
  } 

  li {
    position: relative;
    padding-left: 25px;

    [class*="icons-"] { position: absolute; top: 2px; left: 3px; }

    [class*="icon-"] {
      position: absolute; top: 0; left: 3px;
      text-align: center;
      @include x-rem(font-size, 14px);
    }

    + li { margin-top: 0.5em; }

    a {
      color: #fff;
      text-decoration: none;

      &:hover, &:focus { text-decoration: underline; }
    }
  }

  .postStamp {
    text-align: center;

    //@include breakpoint(m) {
    //  position: absolute; left: 15px; top: 0;     
    //  text-align: left;
    //}

    .time {
      padding-top: 90px;
      background: none;
    }

    .postStampDay { @include x-rem(font-size, 65px); }
    .postStampMonth { @include x-rem(font-size, 36px); }

    &.postStampMulti {
      .time { 
        display: inline-block;
        padding-top: 50px;

        //@include breakpoint(m) { display: block; }
      }
      .postStampDay { @include x-rem(font-size, 35px); }
      .postStampMonth { @include x-rem(font-size, 26px); }
    }
  }

  .buy {
    margin-top: 1em;

    //@include breakpoint(m){
    //  position: absolute; bottom: 0; right: 0;      
    //  margin-top: 0;
    //}

    a {
      position: relative;
      display: block;
      padding: 0.4em 2.5em 0.4em 2em;
      color: #6a747c; font-weight: $fontWeight-bold;
      text-transform: uppercase; text-decoration: none;
      background: #bec6cc;
      //@include transition(all 0.2s ease-out);

      + a { margin:2px 0 0; }

      //@include breakpoint(m){
      //  display:inline-block;
      //  + a { margin:0 0 0 2px; }
      //}

      &:hover, &:focus { background:mix(#bec6cc, #6a747c, 50%); }

      &:after {
        content:'';
        //@extend .icons-arrow-right;
        position:absolute; right:1.5em; top:8px;
      }
    }
  }
}