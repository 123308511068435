/* ================================================================
   Admin Fixes
   ================================================================ */
   .sfPageEditor {
       background:$color-white;
       .sfPublicWrapper { width:auto; padding:0; }
       .btn {
           margin-right: 0;
           &:before, &:after { display: none; }
       }
   }

   .sfPageHasLangVersions {
       font-family: $font-primary;
       font-size:1em;
       line-height: 1.4;
       background:$color-white;

       .sfPublicWrapper { width:auto; padding:0; }
   }