/* Breadcrumbs
   ================================================================ */
.breadcrumb-wrapper {
    margin:clamp(12px, 1.25vw, 24px) 0 clamp(32px, 6.25vw, 120px);
}

.breadcrumb-wrapper--secondary {
    margin-top:0;
    padding-top:9%;
}
.breadcrumb {

}
.breadcrumb__list {
    @extend %listreset;
    display: flex;
    align-items: center;
    gap:16px;
}

.breadcrumb__item {
    display: flex;
    align-items: center;
    gap:12px;
    font-size:clamp(1.125rem, 1.04vw, 1.25rem);
    font-weight:$fontWeight-medium;

    &:after {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background:#757575;
    }

    &:last-child {
        padding-right: 0;
        &:after {
            display: none;
        }
    }
}

.breadcrumb__link {
    color:#757575;
}