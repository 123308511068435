/* ================================================================
   Keyframes
   ================================================================ */


/* Fade Out */
@keyframes fadeOut {
  0% { opacity:1; }
  100% { opacity:0; }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes shrink {
  from {
    transform:scale(1.3);
  }

  50% {
    transform:scale(1);
  }
}



/* ================================================================
   Site Specific Below
   ================================================================ */

*[data-animation^="fade"] {
    opacity:0;
    .no-js &,
    .sfPageEditor & { opacity: 1; transform:none; }

    .slick-cloned & { opacity: 1; transform: none; animation: none; }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fadeInUp {
  animation-name: fadeInUp;
}

.fadeInDown {
  animation-name: fadeInDown;
}

.fadeIn {
  animation-name: fadeIn;
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

.shrink {
  animation-name: shrink;
}