.main-nav__menu {
    @extend %listreset;
    display: flex;
    flex-direction: column;
    gap:clamp(18px, 2.3vw, 45px);
}

.main-nav__link {
    color:$color-base;
}

.main-nav__link.main-nav__link--toggle {
    @extend %buttonreset;
    display: flex;
    align-items: center;
    gap:8px;
    width:100%;
    text-align: left;

    &:after {
        @include pseudo(block, static, '');
        border: solid currentColor;
        border-width: 0 2px 2px 0;
        margin-top:-3px;
        margin-left: auto;
        padding: 3px;
        transform: rotate(45deg);
        will-change: transform, color;
        transition:transform .3s ease-in-out, color .3s ease-in-out;
    }

    &.active {
        color:$color-primary;

        &:after {
            margin-top:6px;
            transform: rotate(-135deg);
        }
    }
}

.main-nav__sub {
    @extend %listreset;
    display: none;
}

// Level 1
.main-nav__menu > .main-nav__item {
    & > .main-nav__link {
        font-size:clamp(1.125rem, 1.04vw, 1.25rem);
        font-weight:$fontWeight-medium;
    }

    // Level 2
    .main-nav__sub {
        margin-top:18px;
        gap:16px;
        flex-direction: column;

        .main-nav__sub {
            padding-left:20px;
        }
    }
}

@media(max-width:$bp-mw-max){
    .main-nav__sub {
        max-width:none !important;
    }
}

@media(min-width:$bp-l){
    .main-nav__menu {
        flex-direction: row;
        align-items: flex-start;
    }

    .main-nav__link {
        color:currentColor;
        transition:color .3s ease-in-out;
        
        &.main-nav__link--toggle {
            width:auto;
        }
    }

    // Level 1
    .main-nav__menu > .main-nav__item {
        width:min-content;

        & > .main-nav__link {
            white-space: nowrap;
        }

        // Level 2
        .main-nav__sub {
            margin-top:22px;
            gap:22px;
            white-space: nowrap;
        }
    }
}