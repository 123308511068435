.location-accordion {
    .tab {
        margin: 0;
        font-size: 1.125rem;
        line-height: normal;
        font-weight: $fontWeight-semibold;

        button {
            position: relative;
            display: block;
            width: 100%;
            padding: 20px;
            background: #f5f5f6;
            border: 1px solid #d3d3d3;
            font-weight: $fontWeight-semibold;
            text-align: left;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:before,
            &:after {
                content: "";
                position: absolute;
                background-color: $color-base;
                transition: transform 0.25s ease-out;
            }

            &:before {
                top: 20px;
                right: 25px;
                width: 4px;
                height: 23px;
            }

            &:after {
                top: 29px;
                right: 16px;
                width: 23px;
                height: 4px;
            }

            &.active {
                border-bottom-color: transparent;
                background: $color-white;

                &:before {
                    transform: rotate(90deg);
                }

                &:after {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .tab-content {
        display: none;
        padding: 0 20px 20px;
        border: 1px solid #d7d7d7;
        border-top: 0;
    }

    @media(min-width:$bp-l) {
        .tab {
            font-size: 2.25rem;

            button {
                padding: 30px;
            }

            button:before {
                top: 40px;
                right: 50px;
            }

            button:after {
                top: 49px;
                right: 41px;
            }
        }

        .tab-content {
            padding: 0 30px 30px;
        }
    }
}
