.dark-gray-bg {
    border-radius: 12px;
    padding:clamp(32px, 5.4vw, 140px) 16px;
    background: radial-gradient(106.9% 43.9% at 89.38% 106.46%, rgba(62, 166, 225, .2) 0, rgba(62, 166, 225, 0) 100%), radial-gradient(190.94% 115.91% at 19.94% -11.95%, rgba(62, 166, 225, .2) 0, transparent 100%), var(--Neutral-Neutral-1, #1b1b1c);
    color:$color-white;

    .sf-contentblock & a {
        color: currentColor;
    }

    .button.button--ghost {
        border-color:currentColor;
        color:currentColor;

        &:hover, &:focus-visible {
            background:$color-white;
            color:$color-base;
        }
    }
}

.white-bg {
    padding:clamp(32px, 5.4vw, 140px) 16px;
    box-shadow: 0 2px 2px -1px rgba(0,0,0,.1), 0 4px 10px -2px rgba(0,0,0,.05), inset 0 0 0 2px hsla(0,0%,100%,.25);
    background:$color-white;
}

.lightblue-gradient-bg {
    border-radius: 12px;
    padding:clamp(32px, 5.4vw, 140px) 16px;
    backdrop-filter: blur(44px);
    background: radial-gradient(106.9% 43.9% at 89.38% 106.46%, rgba(62, 166, 225, .2) 0, rgba(62, 166, 225, 0) 100%), 
                radial-gradient(190.94% 115.91% at 19.94% -11.95%, rgba(62, 166, 225, .2) 0, transparent 100%), 
                var(--Neutral-Neutral-4, #fff);
    box-shadow: 0 2px 2px -1px rgba(0,0,0,.1), 0 4px 10px -2px rgba(0,0,0,.05), inset 0 0 0 2px hsla(0,0%,100%,.25);
}

.blue-gradient-bg {
    border-radius: 12px;
    padding:clamp(32px, 5.4vw, 140px) 16px;
    background: radial-gradient(106.9% 43.9% at 89.38% 106.46%, rgba(151, 71, 255, 0.30) 0%, rgba(151, 71, 255, 0.00) 100%), 
                radial-gradient(190.94% 115.91% at 19.94% -11.95%, rgba(71, 244, 255, 0.30) 0%, rgba(151, 71, 255, 0.00) 100%), 
                var(--Primary-Primary-Compliant, #187DB7);
    box-shadow: 0px 4px 4px -1px rgba(0, 0, 0, 0.15), 0px 8px 16px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px rgba(255, 255, 255, 0.25) inset;
    color:$color-white;

    .sf-contentblock & a {
        color: currentColor;
    }

    .button.button--ghost {
        border-color:currentColor;
        color:currentColor;

        &:hover, &:focus-visible {
            background:$color-white;
            color:$color-base;
        }
    }

    .form-control {
        box-shadow:none;
        border-color:transparent;
    }

    .form-label.form-label--req:after {
        color:currentColor;
    }

    [data-sf-role="form-container"] button[type="submit"].button {
        border:1px solid $color-white;
        background:transparent;
        color:$color-white;
    }

    .invalid-feedback {
        font-weight:$fontWeight-bold;
    }
}

.v-padding-small {
    padding:clamp(8px, 8.3vw, 16px) 0;
}

.v-padding-medium {
    padding:clamp(16px, 16.6vw, 32px) 0;
}

.v-padding-large {
    padding:clamp(24px, 24.9vw, 48px) 0;
}

.centered-columns {
    & > .row {
        align-items: center;
    }
}

@media(min-width:$bp-l){
    .dark-gray-bg {
        border-radius: 24px;

        &.dark-gray-bg--radial {
            position:relative;
            z-index:1;
            overflow: hidden;

            &:before {
                @include pseudo();
                top:0;
                right:0;
                z-index:-1;
                background:url($image-path + "top-right-radial.png") no-repeat 0 0;
                width:50%;
                height:100%;
                background-size:cover;
                opacity: .1;
            }
        }
    }

    .blue-gradient-bg {
        border-radius: 24px;
    }

    .lightblue-gradient-bg {
        border-radius: 24px;

        &.lightblue-gradient-bg--radial {
            position:relative;
            z-index:1;
            overflow: hidden;

            &:before {
                @include pseudo();
                bottom:0;
                left:0;
                z-index:-1;
                background:url($image-path + "bottom-left-radial.png") no-repeat 0 0;
                width:100%;
                height:75%;
                background-size:auto 100%;
                opacity: .5;
                mix-blend-mode: difference;
            }
        }
    }
}