/* ================================================================
   Videos
   ================================================================ */

.video-listing {
    ul.item-list .item + .item { margin-top:16px; border-top:0; }
    ul.item-list .item {
        .summary { margin:0; font-style: none; font-weight: $fontWeight-normal; }
    }

    @media(min-width:$bp-m) {
        ul.item-list {
            display: flex;
            flex-wrap:wrap;
            margin:0 -1em;
            .item {
                width:25%;
                margin:0 0 1em;
                padding:0 1em;
            }
        }    
        ul.item-list .item + .item { margin-top:0; border-top:0; }
    }
}

.video-details .title { margin:1em 0; color:$color-purple; }
.video-details iframe { max-width:100%; }