.callout-slider {
    margin: 2em 0;

    .no-js & {
        .callout-slider__image {
            display: none;
        }

        @media(min-width:$bp-m) {
            white-space: nowrap;
            overflow-x: auto;

            .callout-slider__item {
                display: inline-block;
                vertical-align: top;
                white-space: normal;
            }
        }
    }

    .keyboarder-user & {
        .slick-arrow:focus {
            outline: 2px solid $color-primary;
        }

        .slick-dots button:focus {
            outline: 2px solid $color-primary;
        }
    }

    .slick-arrow {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background: $button-color;
        font-size: 0;
        text-align: center;
        color: $color-white;
        will-change: background;
        transition: background 0.3s ease-in-out;

        &:hover {
            background: darken($button-color, 15%);
        }

        &:before {
            position: static;
            font-size: 20px;
            border: 0;
            transform: none;
        }

        &.slick-prev:before {
            content: "\e900";
        }

        &.slick-next:before {
            content: "\e901";
        }

        &.slick-disabled {
            background: gray;
            cursor: initial;
        }
    }
}

.callout-slider__item {
    padding: 1em;

    &.slick-cloned {
        animation: none;
        opacity: 1;
    }
}

.callout-slider__inner {
}

.callout-slider__link {
    display: block;
}

.callout-slider__media {
    position: relative;
    height: 240px;
    overflow: hidden;
}

.callout-slider__image {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
}

.callout-slider__content {
    position: relative;
    padding: 30px 60px 30px 30px;
    background: $color-gray-3;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 60px 60px;
        border-color: transparent transparent $color-white transparent;
    }
}

.callout-slider__title {
    margin: 0 0 10px;
    font-size: 1.5rem;
    line-height: normal;
    font-weight: $fontWeight-semibold;
    color: $color-black;
}

.callout-slider__summary {
    margin: 0;
}


@media(max-width:65em) {
    .callout-slider__item {
        opacity: 1;
    }
}

@media(min-width:$bp-m) {
    .callout-slider {
        padding: 0 90px;

        .slick-arrow {
            width: 70px;
            height: 70px;
        }

        .slick-arrow:before {
            font-size: 30px;
        }

        .slide-item .media {

            &>a {
                display: block;
                position: relative;
                height: 100%;
                width: 100%;
            }
        }
    }

    .callout-slider__media {
        width: 100%;
        height: 340px;
    }
}

@media (min-width: 64em) {
    .callout-slider__media {
        height: 240px;
    }
}

@media (min-width: $bp-l) {
    .callout-slider__inner {
        box-shadow: 0px -23px 12px 1px rgba(0, 0, 0, 0);
    }

    .callout-slider__link {
        transition: all 0.3s ease-in-out;
        &:hover,&:focus {
            transform: scale(1.1);
            box-shadow: 0px -23px 12px 1px rgba(0, 0, 0, .1);
            text-decoration: none;
            .callout-slider__title {
                color: $color-primary;
            }
        }
    }
}

@media (min-width: 105em) {
    .callout-slider {
        padding: 0;

        .slick-arrow.slick-prev {
            left: -90px;
        }

        .slick-arrow.slick-next {
            right: -90px;
        }
    }
}