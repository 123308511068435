/* ================================================================
   Slideshow Styles
   ================================================================ */
   .slideshow {
        padding-bottom:80px;
        background:#ececec;
       .slide-item {
            position:relative;

            img, .content, .content .title, .content p, .content .button  {
                animation-duration: 1s;
                animation-fill-mode: both;
            }

            img { animation-duration:3s; }

            .content p { animation-delay: 1.1s; }
            .content .title { animation-delay: 1s; }
            .content .button { animation-delay: 1.2s; }

            .content {
                position:relative;
                padding:0 20px;
                &:before {
                    content:"";
                    position:absolute;
                    top:-80px; left:0;
                    width:100%; height:100px;
                    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ececec+100&0+0,1+100 */
                    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(236,236,236,1) 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(236,236,236,1) 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(236,236,236,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ececec',GradientType=0 ); /* IE6-9 */
                }

                .title {
                    position: relative;
                    margin:-20px 0 5px;
                    font-size: 2.25rem;
                    font-weight: $fontWeight-light;
                    line-height: normal;
                }
            }
            &.slick-active {
                img { animation-name:shrink; }
                .content { animation-name:fadeIn; }
                .content .title {  animation-name:fadeInDown; }
                .content p, .content .button { animation-name:fadeInUp; }
            }
       }

       .slick-active {
            img { animation-name:shrink; }
            .content { animation-name:fadeIn; }
            .content .title {  animation-name:fadeInDown; }
            .content p, .content .button { animation-name:fadeInUp; }
        }

       .slick-arrow { 
           top:auto; bottom:10px; 
           height:55px;
           transform:none;
           &:before { width:30px; height:30px; border-color:#5e5e5e; }
        }

       .slick-dots { bottom:0; margin-top:0; }

       @media(min-width:$bp-m){
           padding-bottom:0;
           background:none;

           .slide-item .content { 
               position:absolute;
               top:50%; left:50%;
               width:480px;
               padding:50px;
               background:transparentize($color-white, .15);
               transform:translate(-50%, -50%);
               &:before { display: none; }

               .title { margin:0 0 5px; }
           }

           .slick-arrow {
               top:50%; bottom:auto;
               height:65px;
               transform:translateY(-50%);
               &:before { width:40px; height:40px; border-color:$color-white; }
               &:hover:before { border-color:$color-purple; }
               &.slick-prev { left:5%; }
               &.slick-next { right:5%; }
           }

           .slick-dots { bottom:0; }
       }

       @media(min-width:$bp-mw) {
           .slide-item .content {
               width:520px;
               .title { font-size: 3rem; line-height: 48px; }
           }
       }

       .js-keyboard-user & .slick-arrow:focus { outline:2px solid $color-secondary; }
       .js-keyboard-user & .slick-dots button:focus { outline:2px solid $color-primary; }
   }