/* ================================================================
   Helper Classes
================================================================ */

$textAlignments:left, right, center;

$fontWeights: (
    "normal": $fontWeight-normal,
    "medium": $fontWeight-medium,
    "semibold": $fontWeight-semibold,
    "bold": $fontWeight-bold
);

$fonts: (
    "primary":$font-primary,
    "secondary":$font-secondary
);

$textCase:(
    "upper":"uppercase",
    "lower":"lowercase",
    "cap":"capitalized"
);

$displays:(
    "block":block,
    "inline-block":inline-block,
    "inline":inline,
    "flex":flex,
    "inline-flex":inline-flex
);

$flexDirections:(
    "row":row,
    "row-reverse":row-reverse,
    "column":column,
    "column-reverse":column-reverse
);

$sides: (
    all:"",
    vertical:"y",
    horizontal:"x",
    top:"t", 
    right:"r", 
    bottom:"b", 
    left:"l"
);

$spacer: 1rem;
$numOfSpacers:48;

/** Element Helpers **/
.breakforprint { page-break-after:always; }

.full-width { width:100%; }
.list-unstyled, .unstyled-list { padding-left:0; list-style-type: none; }

.rounded {
    border-radius: 12px;

    @media(min-width:$bp-l){
        border-radius: 24px;
    }
}

$grids: (
    "2x2": 2,
    "3x3": 3,
    "4x4": 4
);

.img-center {
    display: block;
    margin:0 auto;
}

.img-left {
    display: block;
    margin-right:auto;
}

.img-right {
    display: block;
    margin-left:auto;
}

/* Mixins & Functions
================================================================ */
@mixin generate-text-alignment-classes($bpAbbv: '') {
    @each $alignment in $textAlignments {
        .text#{$bpAbbv}-#{$alignment} {
            text-align: $alignment;
        }
    }
}

@mixin generate-display-classes($bpAbbv: '') {
    @each $display, $displayVal in $displays {
        .d#{$bpAbbv}-#{$display} {
            display: $displayVal;
        }
    }
}

@mixin generate-flexDirection-classes($bpAbbv: '') {
    @each $direction, $directionVal in $flexDirections {
        .flex#{$bpAbbv}-#{$direction} {
            flex-direction: $directionVal;
        }
    }
}

@function spacer-value($multiplier) {
    @return $spacer * $multiplier;
}

@mixin generate-spacing-classes($bpAbbv: ''){
    @each $side, $sideValue in $sides {
        @for $i from 0 through $numOfSpacers {
            $spaceValue: spacer-value($i * 0.25);
        
            @if ($sideValue == "") {
                .p#{$sideValue}-#{$bpAbbv}#{$i} {
                    padding: $spaceValue;
                }
                .m#{$sideValue}-#{$bpAbbv}#{$i} {
                    margin: $spaceValue;
                }
            } @else if ($sideValue == "y") {
                .p#{$sideValue}-#{$bpAbbv}#{$i} {
                    padding-top: $spaceValue;
                    padding-bottom: $spaceValue;
                }
                .m#{$sideValue}-#{$bpAbbv}#{$i} {
                    margin-top: $spaceValue;
                    margin-bottom: $spaceValue;
                }
            } @else if ($sideValue == "x") {
                .p#{$sideValue}-#{$bpAbbv}#{$i} {
                    padding-left: $spaceValue;
                    padding-right: $spaceValue;
                }
                .m#{$sideValue}-#{$bpAbbv}#{$i} {
                    margin-left: $spaceValue;
                    margin-right: $spaceValue;
                }
            } @else {
                .p#{$sideValue}-#{$bpAbbv}#{$i} {
                    padding-#{$side}: $spaceValue;
                }
                .m#{$sideValue}-#{$bpAbbv}#{$i} {
                    margin-#{$side}: $spaceValue;
                }
            }

            .gap-#{$bpAbbv}#{$i} {
                gap: $spaceValue;
            }

            .row-gap-#{$bpAbbv}#{$i} {
                row-gap: $spaceValue;
            }

            .col-gap-#{$bpAbbv}#{$i} {
                column-gap: $spaceValue;
            }
        }

        .p#{$sideValue}-#{$bpAbbv}auto {
            padding-#{$side}: auto;
        }

        .m#{$sideValue}-#{$bpAbbv}auto {
            margin-#{$side}: auto;
        }
    }
}

@mixin generate-grid-classes($bpAbbv: ''){
    @each $grid, $value in $grids {
        .grid#{$bpAbbv}-#{$grid} {
            grid-template-columns: repeat($value, minmax(0px, 1fr));
        }
    
        .grid#{$bpAbbv}-#{$grid}-auto {
            grid-template-columns: repeat($value, minmax(auto, auto));
        }
    }
}

/* Text Helpers
================================================================ */
@each $fontWeight, $weightValue in $fontWeights {
    // .text-normal { font-weight:normal; }
    .text-#{$fontWeight} {
        font-weight:$weightValue;
    }
}

@each $alignment in $textAlignments {
    // .text-left { text-align:left; }
    .text-#{$alignment} {
        text-align:$alignment;
    }
}

@each $case, $caseValue in $textCase {
    // .text-upper { text-transform:uppercase; }
    .text-#{$case} {
        text-transform:$caseValue;
    }
}

@each $color, $colorValue in $colors {
    // .color-primary { color:$color-primary }
    .color-#{$color} {
        color:$colorValue;
    }
}

@each $font, $fontValue in $fonts {
    // .font-primary { font-family:$font-primary }
    .font-#{$font} {
        font-family:$fontValue;
    }
}

.smallest { font-size:0.8em; }
.smaller { font-size:0.9em; }
.larger { font-size:1.1em; }
.largest { font-size:1.2em; }

.color-primary { color:$color-primary; }
.color-secondary { color:$color-secondary; }


/* Layout Helpers
================================================================ */
[class*="grid-"] {
    display: grid;
    gap:calc($grid-gutter-em * 2);

    &:is(ul) {
        @extend %listreset;
    }
}

@include generate-grid-classes();

/* Element Helpers
================================================================ */
@include generate-display-classes();
@include generate-flexDirection-classes();
@include generate-spacing-classes();

/* Media Queries
================================================================ */
@each $bpAbbv, $bp in $breakpoints {
    @media(min-width:$bp){
        @include generate-grid-classes('-#{$bpAbbv}');
        @include generate-flexDirection-classes('-#{$bpAbbv}');
        @include generate-display-classes('-#{$bpAbbv}');
        @include generate-text-alignment-classes('-#{$bpAbbv}');
        @include generate-spacing-classes('#{$bpAbbv}-');
    }
}