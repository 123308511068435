/* ================================================================
   Flex Grid - Bootstrap Inspired
================================================================ */

$grid-columns:12;
$colAlignments:flex-start,center,flex-end;


.container {
  width:100%; 
  max-width:$grid-container-width;
  margin-right: auto; 
  margin-left: auto;
  padding-left: $grid-gutter-em;
  padding-right: $grid-gutter-em;
}

.row { 
    display:flex;
    flex-wrap:wrap;
    row-gap:calc($grid-gutter-em * 2);
    margin-left:-$grid-gutter-em;
    margin-right:-$grid-gutter-em;
    text-align: left;
    font-size:1rem;
    color:inherit;

    & > * {
        position:relative;
        width:100%;
        max-width:100%;
        flex-shrink:0;
        padding-left:$grid-gutter-em;
        padding-right:$grid-gutter-em;
    }
}

.col {
    flex:1 0 0%;
}

.col-auto {
    flex:0 0 auto;
    width:auto;
}

@each $alignment in $colAlignments {
    .align-#{$alignment} {
        align-items:$alignment;
    }

    .justify-#{$alignment} {
        justify-content: $alignment;
    }
}

@for $i from 1 through $grid-columns {
    .order-#{$i} {
        order:$i;
    }

    .col-#{$i},
    .row-cols-#{$i} > * {
        flex: 0 0 auto;
        width:calc($i/$grid-columns * 100%);
    }
}

@each $bpAbbv, $bp in $breakpoints {
    @media(min-width:$bp){
        @each $alignment in $colAlignments {
            .align-#{$bpAbbv}-#{$alignment} {
                align-items:$alignment;
            }
        
            .justify-#{$bpAbbv}-#{$alignment} {
                justify-content: $alignment;
            }
        }

        @for $i from 1 through $grid-columns {
            .col-#{$bpAbbv}-#{$i},
            .row-cols-#{$bpAbbv}-#{$i} > * {
                flex:0 0 auto;
                width:calc($i/$grid-columns * 100%);
            }

            .order-#{$bpAbbv}-#{$i} {
                order:$i;
            }

            .col-#{$bpAbbv}-auto {
                flex:0 0 auto;
                width:auto;
            }
        }  
    }
}