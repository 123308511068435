h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin: 0 0 $typography-margin-bottom;
    font-family: $font-heading;
    font-weight:$fontWeight-light;
}

h1,
.h1 {
    font-size: clamp(2.5rem, 3.75vw, 4.5rem);
    line-height: clamp(1.3em,  1.4vw, 1.7em);
}

h2,
.h2 {
    font-size: clamp(40px, 2.9vw, 3.5rem);
    line-height: 1.1;
}

h3,
.h3 {
    font-size: clamp(2rem, 2.6vw, 3.125rem);
    line-height: 1.3;
}

h4,
.h4 {
    font-size: clamp(1.5rem, 2.1vw, 2.5rem);
    line-height: 1.3;
}

h5,
.h5 {
    font-size:clamp(1.25rem, 1.5vw, 1.875rem);
    line-height:1.3;
}

h6,
.h6 {
    font-size:clamp(1.125rem, 1.04vw, 1.25rem);
    line-height: 1.5;
}

.subhead {
    font-size:clamp(1rem, 1.35vw, 26px);
    line-height: 1.5;
}

.subhead-2 {
    font-size:clamp(1rem, 1.04vw, 1.25rem);
    line-height: 1.5;
}

.text-large {
    font-size:clamp(0.875rem, 1vw, 1.125rem);
}

.text-small {
    font-size:clamp(0.75rem, .73vw, 0.875rem);
}

a {
    color: $color-primary;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

p {
    margin: 0 0 1.5rem;
}

ul, ol {
    padding-left:1.25rem;
}

.site-main .sf-contentblock {
    font-size:clamp(1rem, .93vw, 1.125rem);

    a:not(.button) {
        text-decoration: underline;
        font-weight:$fontWeight-bold;
        color:$color-base;

        &:hover,
        &:focus-visible {
            text-decoration: none;
        }
    }
}

.site-main [class*="row-cols"] > .sf-contentblock {
    display: flex;
    flex-direction: column;

    & > *:last-child {
        display: block;
        margin-top:auto;
    }
}