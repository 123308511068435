/* PRODUCTS - LEVEL 3 DETAILS */
#main-content .level-3 h1 {
  padding-bottom: 20px;
}
#main-content .level-3 h3,
#main-content .top-right-level-3 h3.level-3-subhead,
#main-content .top-left-level-3 h3.level-3-subhead {
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 8px;
}
#main-content .level-3 h3.level-3-subhead,
#main-content .top-right-level-3 h3.level-3-subhead,
#main-content .top-left-level-3 h3.level-3-subhead {
  padding-bottom: 3px;
  color: #414141;
  text-align: left;
}
#main-content .level-3 ul,
#main-content .top-left-level-3 ul {
  margin-left: 13px;
}
#main-content .level-2 ul,
#main-content .level-3 ul,
#main-content .top-left-level-3 ul {
  margin: 0 0 17px 13px;
}
#main-content .level-3 ul li,
#main-content .level-2 ul li,
#main-content .top-left-level-3 ul li {
  list-style-type: disc;
  padding-bottom: 8px;
  line-height: 1.3em;
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-weight: normal;
  font-size: 13px;
  color: #414141;
}
#main-content .top-left-level-3,
#main-content .top-left-level-2 {
  width: 500px;
  margin-right: 120px;
  float: left;
}
#main-content .top-right-level-3,
#main-content .top-right-level-2 {
  width: 255px;
  float: left;
  text-align: center;
}
#main-content .top-right-level-3 .image-area {
  max-height: 165px;
  padding: 0;
  line-height: 165px;
  vertical-align: middle;
}
#main-content .top-right-level-2 .image-area {
  max-height: 165px;
  margin: 60px 0 25px 0;
  line-height: 165px;
  vertical-align: middle;
}
#main-content .top-right-level-3 img,
#main-content .top-right-level-2 img {
  margin: auto;
  max-height: 165px;
  line-height: 165px;
  vertical-align: middle;
}
#main-content .top-right-level-3 .links {
  margin-bottom: 7px;
  text-align: left;
}
#main-content .top-right-level-2 .parametric-search img {
  padding: 8px 0 25px 0;
}
#main-content .top-right-level-3 a {
  margin-top: 10px;
}
#main-content .top-right-level-3 img.blue-arrow,
#main-content .level-1 img.blue-arrow {
  display: inline;
  margin: 0 0 0 5px;
  padding: 0;
}
#main-content .top-right-level-3 .contact-knowles,
#main-content .top-right-level-3 .parametric-search,
#main-content .top-right-level-2 .contact-knowles {
  margin-bottom: 25px;
  width: 255px;
}
#main-content .level-3-table,
#main-content .level-2-table,
#main-content .level-2-content {
  clear: both;
  padding-top: 25px;
}
#main-content .level-3-table h4,
#main-content .level-2-table h4 {
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-weight: bold;
  font-size: 15px;
  color: #933c91;
  width: 400px;
  line-height: 18px;
  margin-bottom: 25px;
}
#main-content .level-1 h2 {
  padding-bottom: 10px;
}
#main-content .level-1 ul.management h2 {
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 10px;
  color: #414141;
}
#main-content .level-1 ul.management h3 {
  color: #7c7b7b;
  font-family: "Graphik Web", sans-serif;
  font-size: 15px;
  font-weight: lighter;
  padding-bottom: 10px;
}
#main-content .level-1 h3.sub-title {
  color: #7c7b7b;
  font-family: "Graphik Web", sans-serif;
  font-size: 15px;
  font-weight: lighter;
  padding-bottom: 10px;
}
#main-content .level-1 h2 a {
  color: #933c91;
  font-family: "Graphik Web", sans-serif;
  font-size: 30px;
  font-weight: lighter;
  line-height: 1em;
}
#main-content .level-1 h2 a:hover {
  color: #7c7b7b;
}
#main-content .level-1 p {
  margin-bottom: 1.5em;
  padding-bottom: 0;
}
ul.child-items {
  margin-top: 60px;
}
#main-content .level-1 img {
  float: left;
  margin: 0 60px 40px 0;
  padding: 0;
  clear: both;
  border: 1px solid #dfe0e0 !important;
}
#main-content .level-1 .links {
  width: 60px;
  margin-bottom: 35px;
}
#main-content .level-1 .links img {
  float: right;
  margin-top: 2px;
  padding: 0;
  border: none !important;
}
#main-content .level-1 .product-info,
#main-content .level-1 .our-leadership {
  width: 500px;
  float: left;
}
#main-content .level-1 .management .product-info {
  margin: 0 0 18px 0;
}
#main-content .level-1 .product-info.middle {
  line-height: 165px;
}
#main-content .level-1 .product-info p {
  padding-bottom: 10px;
  margin-bottom: 0;
  font-size: 13px;
}
#main-content .level-1 .product-info ul {
  margin: 10px 0 10px 15px;
}
#main-content .level-1 .product-info ul li {
  color: #414141;
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  list-style-type: disc;
  padding-bottom: 8px;
  line-height: 1.3em;
}
#main-content .level-1 ul li {
  color: #414141;
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  padding-bottom: 8px;
  line-height: 1.3em;
}
#main-content .level-2 table {
  color: #414141;
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-size: 8pt;
  font-weight: normal;
  margin: 10px 0 15px;
  text-align: left;
  width: 100%;
}
#main-content .level-2 table tr {
  border-top: 1px solid #dfe0e0;
}
#main-content .level-2 table thead th,
#main-content .level-3 table thead th {
  border-bottom: 1px solid #dfe0e0;
  border-right: 1px solid #dfe0e0;
  border-top: 1px solid #dfe0e0;
  color: #933c91;
  cursor: pointer;
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-size: 0.75rem;
  font-weight: $fontWeight-bold;
  height: 38px;
  padding: 0 20px 0 10px;
  vertical-align: middle;
}
#main-content table.techbulletin_appnotes thead th {
  cursor: default;
}
#main-content .level-2 table thead tr .last,
#main-content .level-3 table thead tr .last {
  border-right: medium none;
}
#main-content .level-2 table .line-break {
  display: block;
  margin-top: 5px;
}
table.tablesorter tbody td.series {
  color: #3eb0e1;
  font-weight: bold;
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-size: 15px;
}
#main-content .level-2 p {
  color: #414141;
  font-weight: normal;
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-size: 13px;
}

/*tables styles--------------------------*/
/* tables */
table.tablesorter {
  font-family: Raleway, sans-serif;
  font-weight: normal;
  font-size: 0.75rem;
  color: #414141;
  margin: 10px 0pt 15px;
  font-size: 8pt;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #dfe0e0;
}
table.tablesorter tr {
  border-top: 1px solid #dfe0e0;
}
table.tablesorter thead tr th,
table.tablesorter tfoot tr th {
  border: 1px solid #fff;
  font-size: 8pt;
  padding: 4px;
}
table.tablesorter thead tr .tablesorter-header {
  background-image: url(/web/20180410173240im_/http://www.knowles.com/extension/knowles/design/knowles/images/bg.gif);
  background-repeat: no-repeat;
  background-position: center right;
  cursor: pointer;
  color: #933c91;
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-weight: $fontWeight-bold;
  font-size: 0.75rem;
  height: 38px;
  vertical-align: middle;
  padding: 0 20px 0 10px;
  border-top: 1px solid #dfe0e0;
  border-bottom: 1px solid #dfe0e0;
  border-right: 1px solid #dfe0e0;
  text-align: left;
}
table.tablesorter thead tr td {
  border-left: 1px solid #dfe0e0;
}
table.tablesorter td .dimension-gray {
  color: #7c7b7b;
  margin-top: 5px;
  display: block;
}
table.tablesorter thead tr .last {
  border-right: none;
}
table.tablesorter tbody td {
  color: #3d3d3d;
  padding: 20px 15px 10px;
  background-color: #fff;
  vertical-align: top;
  border-left: 1px solid #dfe0e0;
  line-height: 1.4;
}
table.tablesorter tbody td p {
  font-size: inherit;
}

table.tablesorter tbody td:first-child {
  border-left: none;
}
table.tablesorter tbody tr.odd td {
  background-color: #f0f0f6;
}
table.tablesorter thead tr .tablesorter-headerAsc {
  background-image: url(/web/20180410173240im_/http://www.knowles.com/extension/knowles/design/knowles/images/asc.gif);
}
table.tablesorter thead tr .tablesorter-headerDesc {
  background-image: url(/web/20180410173240im_/http://www.knowles.com/extension/knowles/design/knowles/images/desc.gif);
}
table.tablesorter thead tr .tablesorter-headerDesc,
table.tablesorter thead tr .tablesorter-headerAsc {
  background-color: #f1f1f2;
}
table.tablesorter td .download-PDF {
  min-width: 90px;
  width: 100%;
  margin-bottom: 5px;
}
table.tablesorter td .download-text {
  min-width: 90px;
  width: 100%;
}
table.tablesorter td .download-PDF a {
  font-size: 12px;
}
table.tablesorter td .download-PDF img {
  padding-bottom: 4px !important;
  vertical-align: middle;
}
.tablesorter .tablesorter-header.sorter-false {
  background-image: url(/web/20180410173240im_/http://www.knowles.com/extension/knowles/design/knowles/stylesheets/tablesorter.css);
}

// audio table

.audio-table {
  display: block;
  width: 100%;

  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-color: #fff;
    margin: 0 auto 20px;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr:nth-of-type(odd) {
    background: #d0dded;
  }

  tr:nth-of-type(even) {
    background: #e8eff7;
  }

  td {
    border: none;
    border-bottom: 1px solid #fff;
    position: relative;
    padding: 1em 1em 1em 50%;
    text-align: center;
    vertical-align: middle;
  }

  td:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1em;
    width: 20%;
    padding-right: 1em;
    white-space: nowrap;
  }

  td:nth-of-type(1) {
    &:before {
      content: "Listening Experience";
      font-weight: $fontWeight-bold;
    }
  }
  td:nth-of-type(2) {
    text-align: center;
    &:before {
      content: "Wind Noise";
      font-weight: $fontWeight-bold;
    }
  }
  td:nth-of-type(3) {
    text-align: center;
    &:before {
      content: "Background Noise";
      font-weight: $fontWeight-bold;
    }
  }
  @media only screen and (min-width: 768px) {
    display: table;

    thead {
      display: table-header-group;
    }
    tbody {
      display: table-row-group;
    }
    td,
    th {
      display: table-cell;
    }
    tr {
      display: table-row;
    }

    thead tr {
      position: static;
    }

    th {
      background: $color-primary;
      color: white;
      font-weight: bold;
    }

    td,
    th {
      padding: 1em;
      border: 2px solid #fff;
      text-align: left;
    }

    td:nth-of-type(1),
    td:nth-of-type(2),
    td:nth-of-type(3) {
      &:before {
        content: none;
      }
    }
  }

  .play-btn {
    border: none;
    background: transparent;
    vertical-align: middle;
  }

  .play-btn svg {
    height: 50px;
    width: 50px;
  }

  .icon-pause {
    display: none;
  }
}
