.site-footer {
    border-radius: 24px;
    padding:clamp(32px, 2.9vw, 56px) 16px;
    background: radial-gradient(106.9% 43.9% at 89.38% 106.46%, rgba(62, 166, 225, 0.20) 0%, rgba(62, 166, 225, 0.00) 100%), radial-gradient(190.94% 115.91% at 19.94% -11.95%, rgba(62, 166, 225, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), var(--Neutral-Neutral-1, #1B1B1C);
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.25) inset;
    
    color:$color-white;
    
    a {
        color:currentColor;
    }

    svg {
        fill:currentColor;
    }

    .sf-contentblock a {
        text-decoration: none;

        &:hover,
        &:focus-visible {
            text-decoration: underline;
        }
    }
}

.footer-layout {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap:clamp(40px, 6.25vw, 120px);
    max-width:1500px;
    margin:0 auto;
}

.footer-col.footer-col--logo {
    display: flex;
    flex-direction: column;
    gap:40px;
    text-align: center;
}

.footer-col.footer-col--socials {
    ul {
        @extend %listreset;
        display: flex;
        align-items: center;
        justify-content: center;
        gap:16px;
    }
}

.footer-col.footer-col--links {
    ul {
        @extend %listreset;
        display: flex;
        flex-direction: column;
        gap:24px;
        align-items: center;

        a {
            font-weight:$fontWeight-medium;
        }
    }
}

@media(min-width:$bp-l){
    .footer-layout {
        justify-content: flex-end;
        flex-direction: row;
    }

    .footer-col.footer-col--logo {
        margin-right:auto;
        gap:30px;
        text-align: left;
    }

    .footer-col.footer-col--socials {
        justify-content: flex-end;

        ul {
            justify-content: flex-end;
        }
    }

    .footer-col.footer-col--links ul {
        gap:8px;
        align-items: flex-end;
        a {
            font-weight:$fontWeight-normal;
        }
    }
}