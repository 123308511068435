/* ================================================================
   Hawksearch Overwrites
   ================================================================ */

/** Hide Stuff **/
#hawktabs p,
.hs-result-headers {
    display: none;
}

/** Rail Navigation/Filter Overwrites **/
.hawkRailNav {
    border: 0;
    background: none;

    @media(min-width:$bp-m) {
        max-width: 280px;
    }
}

// mobile rail nav only
.hawk-railNav {
    &.hawk-notCollapsed {
        .hawk-navGroupContent {
            display: block;
        }
    }
}
.hawk-railNavHeading {
    margin-bottom: 25px;
    padding: 0;
    background: none;
    font-size: 1.5rem;
    font-weight: $fontWeight-bold;
    color: $color-black;

    @media(max-width:$bp-sw-max) {
        padding: 10px;
        background: $color-primary;
        color: $color-white;

        &:after {
            border-color: darken($color-primary, 20%);
            border-bottom-color: $color-primary;
        }
    }
}


.hawk-guidedNavWrapper {
    .hawk-groupHeading {
        padding: 0 50px 0 20px;
        font-size: 1.125rem;
        font-weight: normal;
        text-align: left;
        &:after {
            top: 50%;
            right: 25px;
            transform: translateY(-50%);
            color: $color-black;
        }
    }

    .hawkRailNav__toggle {
        background: none;
        border: 0;
        padding: 0;
    }
    .hawk-navGroupContent {
        padding: 20px;
    }
    .hawk-collapsed {
        .hawk-navGroupContent {
            display: none;
        }
    }

    .hawk-navGroup {
        border: 1px solid #d7d7d7;
        border-bottom: 0;
        background: #f5f5f6;

        &+.hawk-navGroup {
            border-top: 1px solid #d7d7d7;
        }

        &:last-child {
            border-bottom: 1px solid #d7d7d7;
        }
    }

    .hawk-navGroup ul {
        padding: 20px;
        border-color: #d6d6d6;
    }

    .hawk-navGroup li {
        margin-top: 0;

        &+li {
            margin-top: 15px;
        }

        a {
            font-size: 1rem;
            color: #58585a;

            &:hover,
            &:focus {
                color: $color-primary;
            }
        }

        .hawk-facetCount {
            display: none;
        }

        .hawk-styleCheckbox,
        a.hawk-styleCheckbox:link {
            padding: 0 0 0 30px;
            border-top: 0;

            &:before {
                top: 4px;
                left: 0;
                width: 15px;
                height: 15px;
                margin-top: 0;
                padding: 3px 0;
                border-color: #d6d6d6;
                background: $color-white;
                color: $color-purple;
            }
        }
    }

    .hawk-selectedNav {
        margin-bottom: 1em;
        background: #f5f5f6;
        border-bottom: 1px solid #d7d7d7;
    }
}

/** Tab Styling **/
#hawktabs .hawk-searchView {
    margin-bottom: 0;
    border-bottom: 0;

    .hawk-viewOption {
        margin-right: 10px;
        margin-bottom: 0;
        border-bottom: 0;

        &:after {
            display: none;
        }

        &:last-child {
            margin-right: 0;
        }

        .hawktab {
            display: block;
            padding: 15px 20px;
            border: 1px solid transparent;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
            background: #ececec;
            font-size: 1.125rem;
            font-weight: normal;
            text-decoration: none;
            color: $color-black !important;

            &:hover,
            &:focus {
                background: $color-purple;
                color: $color-white !important;
            }
        }

        &.hawk-viewOptionOn .hawktab {
            background: $color-purple;
            color: $color-white !important;
        }
    }
}

/** Listing Control Overwrites **/
.hawk-listingControls {
    border: 0;
    border-top: 1px solid #c5c5c5;
    border-bottom: 1px solid #c5c5c5;
    color: $color-black;

    .sortList {
        float: none;
        display: block;
        margin: 1em 0;

        select {
            margin-right: auto;
        }
    }

    label,
    .sortList label {
        font-family: $font-primary;
        font-size: 1.125rem;
        font-weight: normal;
        color: $color-black;
    }

    select {
        width: auto;
        height: 44px;
        margin: auto;
        padding-left: 6px;
        border-radius: 0;
        box-shadow: none;
    }

    .hawk-mainControls {
        padding: 0;
        background: transparent;
        text-align: center;
    }

    .hawk-sortWrapper {
        float: none;
        display: block;
        margin: 0;
    }

    .hawk-pagination {
        float: none;
        margin: 0;

        .hawk-paging,
        .hawk-viewNumber {
            float: none;
        }

        .hawk-viewNumber {
            margin-left: 0;
        }

        .hawk-paging {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin: 1em 0;

            a.hawk-pageLink,
            a.hawk-pageLink:link {
                display: inline-block;
                vertical-align: baseline;
                padding: 0 8px;
                min-width: 0;
                font-size: 1.125rem;
                text-decoration: underline;

                &:hover,
                &:focus {
                    text-decoration: none;
                }

                &.hawk-arrowRight,
                &.hawk-arrowRight:link,
                &.hawk-arrowLeft,
                &.hawk-arrowLeft:link {
                    position: relative;
                    width: 47px;
                    height: 44px;
                    border: 1px solid #d7d7d7;
                    border-radius: 4px;
                    background: #ececec;
                    text-decoration: none;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 20px;
                        border: 6px solid transparent;
                        transform: translateY(-50%);
                        will-change: border-color;
                        transition: border-color 0.3s ease-in-out;
                    }

                    &:before {
                        display: none;
                    }

                    &:hover,
                    &:focus {
                        background: $color-primary;
                        border-color: transparent;
                        text-decoration: none;
                    }
                }

                &.hawk-arrowLeft,
                &.hawk-arrowLeft:link {
                    margin-right: 10px;

                    &:after {
                        left: 13px;
                    }
                }

                &.hawk-arrowRight {
                    margin-left: 10px;
                }

                &.hawk-arrowLeft:after,
                &.hawk-arrowLeft:link:after {
                    border-right-color: #58585a;
                }

                &.hawk-arrowRight:after,
                &.hawk-arrowRight:link:after {
                    border-left-color: #58585a;
                }

                &.hawk-arrowLeft:hover:after,
                &.hawk-arrowLeft:focus:after {
                    border-right-color: $color-white;
                }

                &.hawk-arrowRight:hover:after,
                &.hawk-arrowRight:focus:after {
                    border-left-color: $color-white;
                }
            }

            .hawk-pageActive {
                display: inline-block;
                vertical-align: baseline;
                min-width: 0;
                padding: 0 8px;
                background-color: transparent;
                font-size: 18px;
                color: $color-black;
            }

            .total {
                order: -1;
                color: $color-black;
            }
        }
    }

    @media(min-width:$bp-m) {
        select {
            margin: 0;
        }

        label {
            margin: 0 15px 0 0;
        }

        .hawk-mainControls {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 30px 0;
            text-align: left;
        }

        .sortList {
            display: flex;
            align-items: center;
            padding: 0;
            margin: 0 30px 0 0;

            label {
                margin-right: 12px;
                white-space: nowrap;
            }
        }

        .hawk-pagination {
            display: flex;
            align-items: center;
            flex-grow: 2;
            margin: 0;

            .hawk-viewNumber {
                display: flex;
                align-items: center;
                flex-grow: 2;

                &:before {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 12px 0 0;
                }
            }

            .hawk-paging {
                margin: 0;

                .total {
                    margin-right: 30px;
                }
            }
        }
    }
}

/** Listing Item Styles/Overwrites **/
#hawkitemlist .item {
    padding: 30px 0;

    .media {
        margin-bottom: 1em;
        text-align: center;

        img {
            display: block;
            margin: 0 auto;
        }
    }

    .title {
        font-size: 24px;
        color: $color-purple;

        a {
            text-decoration: none;
            color: $color-purple;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }

    ul.item-associations {
        margin-bottom: 1em;
    }

    @media(min-width:$bp-m) {
        display: table;
        width: 100%;

        .media,
        .content {
            display: table-cell;
            vertical-align: top;
        }

        .media {
            width: 255px;

            img {
                width: 100%;
                margin: 0;
            }

            &+.content {
                padding-left: 40px;
            }
        }
    }
}