ul.pagination {
	display: table;
	width:auto;
    margin:1em 0;
	padding:0;
	white-space: nowrap;
	overflow: hidden;
	overflow-x: auto;
	border:1px solid #ddd;
	list-style-type: none;
	li {
		display: table-cell; 
		vertical-align: middle;
		border-left:1px solid #ddd;
		margin:0;
		&:first-child {border-left:0;}
		a {
			display: block;
			padding:15px;
			background:#f5f5f5;
			text-decoration: none;
			color:$color-black;
			&:hover, &:focus {background:$color-white; color:$color-primary;}
			.sr-only {display: none;}
		}
		&.active a {background:$color-white; font-weight:bold; color:$color-primary;}
	}	
}