/* ================================================================
   Boilerplate - Base Styles 
   ================================================================ */

html,
button,
input,
select,
textarea {
    color: $color-base;
}

body {
    padding:0 clamp(8px, .83vw, 16px) clamp(8px, .83vw, 16px);
    font-family: $font-primary;
    line-height: 1.7;
    overflow-y: scroll;
    background: $body-bg;
    font-optical-sizing: auto;
}

header,
main,
footer,
nav,
section,
aside,
figure {
    outline: 0;
}

::-moz-selection {
    background: $color-primary;
    color: $color-white;
    text-shadow: none;
}

::selection {
    background: $color-primary;
    color: $color-white;
    text-shadow: none;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

hr {
    display: block;
    margin: 1em 0;
    padding: 0;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
}

img {
    vertical-align: middle;
    width: auto;
    height: auto;
    max-width: 100%;
}

.image-fluid,
.image-fluid img {
    max-width: none;
    width: 100%;
    height: auto;
}

.image-fixed,
.image-fixed img {
    max-width: none;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

iframe {
    max-width: 100%;
}

.hidden {
    display: none !important;
    visibility: hidden;
}

.visuallyhidden,
.visually-hidden,
.visuallyHidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    margin: -1px;
    padding: 0;
    border: 0;
    width: 1px;
    height: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    position: static;
    overflow: visible;
    clip: auto;
    margin: 0;
    width: auto;
    height: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix {

    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}

.float-right {
    float: right
}

.float-left {
    float: left
}

table {
    max-width: 100%;
    margin-bottom: 20px;
    border-spacing: 0;
    border-collapse: collapse;
    background-color: transparent;

    caption {
        padding: 8px 0;
        text-align: left;
        color: #777;
    }

    &>thead:first-child>tr:first-child>th {
        border-top: 0;
    }

    &>thead>tr>th {
        vertical-align: bottom;
        padding: 8px;
        border-top: 0;
        border-bottom: 2px solid #ddd;
        line-height: 1.42857143;
    }

    &>tbody>tr>th,
    &>tbody>tr>td,
    &>tfoot>tr>th,
    &>tfoot>tr>td {
        padding: 8px;
        border-top: 1px solid #ddd;
        line-height: 1.42857143;
        vertical-align: top;
    }

    &.table-striped>tbody>tr:nth-child(odd) {
        background-color: #f9f9f9;
    }

    &.table-bordered {
        border: 1px solid #ddd;

        &>thead>tr>th {
            border: 1px solid #ddd;
            border-bottom-width: 2px;
        }

        &>tbody>tr>th,
        &>tbody>tr>td,
        &>tfoot>tr>th,
        &>tfoot>tr>td {
            border: 1px solid #ddd;
        }
    }
}


body span.gc-cs-link {
    // ignores the google voice plugin color
    color: inherit;

    &:hover {
        text-decoration: none;
    }
}

.scroll-cover {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: auto !important;
    z-index: 10000;
}

.svg-legend {
    position: absolute;
    top: -9999em;
    left: -9999em;
    width: 0;
    height: 0;
    overflow: hidden;
}

// Wrapper to implement around SVG's that you want to transform because firefox sucks.
.svg-wrapper {
    transition: transform 0.3s ease-in-out;
}

svg.icon {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    transition: all 0.3s ease-in-out;
}

.sf-Code pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
}

figure {
    margin: 1em 0;
}

ul.skip-links {
    @extend .list-unstyled;
}

.skip-link {
    @extend .visually-hidden;

    &:focus {
        position: static;
        display: block;
        width: auto;
        height: auto;
        margin: 0;
        padding: 10px 0;
        background: $color-primary;
        text-align: center;
        color: $color-white;
    }
}

.site-main__top:empty {
    padding-top:150px;
}

// Adds custom list styles to content
.sfContentBlock {

    ul,
    ol {
        padding-left: 1.5em;
        margin: 0 0 1em;
    }
}

.list-unstyled {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

// Very simple blockquote styling
blockquote {
    margin: 1em 1.5em;
    padding-left: 1.5em;
    border-left: 5px solid hsl(0, 0%, 85%);
}