/* ================================================================
   Variables
   ================================================================ */

$input-height: 56px !default;
$input-background: #ffffff !default;
$input-border-color: #ccc !default;
$input-border-width: 1px !default;
$input-border-radius: 4px !default;
$input-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !default;
$input-box-shadow-active: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px transparentize($color-primary, .4) !default;
$input-border-color-active:$color-primary;


/* ================================================================
   Wraps
   ================================================================ */

.form {
	margin:15px 0; padding:15px; 
}

.form-group { margin-bottom:1em; }

.form-inline {
    @media(min-width:$bp-m) {
        .form-group {
            display: inline-block;
            margin-bottom: 0;
            vertical-align: middle;
        }
        .form-control {
            display: inline-block;
            width: auto;
            vertical-align: middle;
        }
        .input-group {
            display: inline-table;
            vertical-align: middle;
            & > .form-control { width:100%; }
        }
    }
}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate;

    .form-control {
        position: relative;
        display: table-cell;
        z-index: 2;
        float: left;
        width: 100%;
        margin-bottom: 0;
        &:focus { z-index: 3; }
    }

    .input-group-addon {
        width:1%;
        padding:6px 12px;
        font-weight:normal;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
    }

    .input-group-btn {
        position:relative;
        display:table-cell;
        font-size:0;
        white-space: nowrap;
        .btn {
            position:relative;
        }
    }
}



/* ================================================================
   Labels/Indicators
   ================================================================ */

.form-label-wrap { }

.form-label { 
	display:block;

    &.form-label--req {
        &:after { content: " *"; color: red; }
    }
}

.required-indicator {  
	position:relative;
	padding-left:10px;

	&:before { 
		display:block; content:'*';
		position:absolute; left:0; top:0;
		color:red;
	}
}

.error-message {
	margin-top:5px;
	@include x-rem(font-size, 12px); color: red;
}

.form__error {
    display: block;
    margin: 5px 0 0;
    color: $color-error;
}

 



/* ================================================================
   Controls/Inputs
   ================================================================ */

.form-control {
  display: block;
  width: 100%;
  height: $input-height;
  border-radius: 8px;
  border:1px solid #757575;
  padding: 0 16px;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  &:focus {
      border-color: $input-border-color-active;
      box-shadow: $input-box-shadow-active;
  }
  &::-moz-placeholder { color:#757575; opacity:1; }
  &:-ms-input-placeholder { color:#757575; }
  &::-webkit-input-placeholder { color:#757575; }
  &::-ms-expand { border:0; background-color:transparent; }

  &[disabled], &[readonly]{ background:#e9ecf0; opacity:1; cursor: not-allowed; }
}


select,
textarea,
input[type="tel"],
input[type="text"],
input[type="email"],
input[type="search"],
input[type="number"],
input[type="password"] {
    @extend .form-control;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}


input[type="text"], input[type="search"] { -webkit-appearance: none !important; }

input[type="checkbox"],  
input[type="radio"] { 
	margin-right: 2px;
	border-radius: 0;
	background: none;
}

select { padding-right:0; }

textarea,
textarea.form-control {
    padding-top:8px;
    padding-bottom:8px;
    min-height:85px;
}

// Text input areas
.sfreContentArea {
	padding: 10px 13px;
	background: none;
}


.sf-FileField .sf-icon-remove {
	display: inline-block;
	cursor: pointer;
}

input[type=file].sf-FileField-input { display: inline-block; }

.sf-designer-dlg .form-control { display:inline-block; }

 

/* ================================================================
   Sitefinity Form Styles
   ================================================================ */

// Resets a bunch of sign up forms and makes them block instead of list style.
.sfLoginFieldsWrp, .sfprofileFieldsList, .sfregisterFieldsList {
	display: block;
	margin:1em 0; padding:0;

	li {
		display: block;
		margin:0; padding:0;

		+ li { margin-top:1em; }
	}
}

// RadEditor is Sitefinity's special textarea/lite WYSIWYG. Trying to catch most of the styling here but I'm sure there's more to be done.

.Sitefinity.RadEditor {
	td { background:none; }

	// Unnecessary padding cell to the left. No idea what's inside so hiding if empty;

	.reTlbVertical {
		&:empty { display:none; }
	}
    
    //can't do :empty as empty for this means &nbps; :(
	.reLeftVerticalSide { display:none;  }

	.reContentCell {
		border: none;

		iframe {
			border: $input-border-width solid $input-border-color; 	
			box-shadow:$input-box-shadow;	
			background: $input-background;
		}
	}

	// Adjust the "formatting options box"
	.reToolbarWrapper {
		position: absolute; top: -6px;  right: 0;
		margin-right: 0 !important; // overrides inline styles
	}
 
	.sfMoreTools { min-width: 0 !important; } //overrides inline styles 
}


// Textbox sizes as defined by the backend editor
.sfTxtBoxSmall .sfTxt,
.sfDdlSmall select { max-width:120px; }

.sfTxtBoxMedium .sfTxt,
.sfDdlMedium select  { max-width:300px }

.sfTxtBoxLarge .sfTxt,
.sfDdlLarge select  { max-width:400px }

.sfExample {	
    margin: 0.5em 0 1em;
	font-size: .6875rem;
	line-height: 1.4em;
}


/* Error Message
   ========================================================================== */

.sfError {
	margin-top: calc($grid-gutter / 4); padding: calc($grid-gutter / 2) $grid-gutter;
	@include x-rem(font-size, 12px); color: #fff;
	background: red;
}