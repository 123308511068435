/* ==========================================================================
   Global Modules
   - We define modules as larger content that usually have a listing and detailed view.
   ========================================================================== */


/* Module
   - Used to define large, page wide tools (e.g. News listing, Events Calendar)
   ========================================================================== */

.module {
    margin-bottom: 1em;

    .header {
        .title {
            margin-bottom: 0;
        }
    }

    .content {
        margin-bottom: $typography-margin-bottom;
    }

    .summary {
        margin-bottom: $typography-margin-bottom;
        font-weight: $fontWeight-bold;
        font-style: italic;
    }
}


/* Widget
   - Used to define individual parts that make up a page (e.g. News rail listing)
   ========================================================================== */

.widget {
    margin-bottom: 1em;

    .header {
        padding: 10px;
        background: $color-primary;

        .title {
            margin: 0;
        }
    }

    .content {
        padding: 10px;
    }

    .summary {}
}


/* Item
   - Used to define single piece within a component (e.g. News article within a news listing)
   ========================================================================== */

.item {
    position: relative;
    display: block;
    padding: 0;

    // Supports aside content in the list
    .aside {
        margin: 1em auto;
        max-width: 200px;
        //max-height: 200px;
    }
}

@media (min-width:$bp-m) {
    .item {
        .aside {
            display: table-cell;
            width: 80px;
            vertical-align: top;
        }

        .item-content {
            display: table-cell;
            vertical-align: top;
            padding: 0;
        }

        .aside+.item-content {
            padding-left: 15px;
        }
    }
}


/* Meta Content
   - This is content that extends or defines the content that it is pared with.
   - Examples: date, author, tag
   ========================================================================== */

.meta {
    display: block;
    margin: 0 0 $typography-margin-bottom;
    padding: 0;
}

.meta-item {
    display: block;

    &.author {
        font-weight: bold;
        @include x-rem(font-size, 15px);
    }

    &.date {
        margin-top: 10px;
        font-style: italic;
        @include x-rem(font-size, 16px);
    }
}


/* Core List Styles
   - Provides core styling for item list
   ========================================================================== */

.item-list {
    display: block;
    list-style: none;
    margin: 0 0 $grid-gutter;
    padding: 0;

    .item+.item {
        margin-top: $grid-gutter;
        padding-top: $grid-gutter - 5px; // offset for line-height on title
        border-top: 1px solid rgba($color-primary, 0.2);
    }
}

/* Hinted Input 
========================================================================== */

.hinted-input {
    position: relative;
    overflow: hidden;

    input {
        ~.hint {
            position: absolute;
            top: 50%;
            left: 10px;
            margin: 0;
            opacity: 1;
            font-size: 1rem;
            white-space: nowrap;
            cursor: text;
            color:#757575;
            transform: translateY(-50%);
            transition: all 0.3s ease-in-out;
        }

        &:focus~.hint,
        &[required]:valid~.hint,
        &.valid~.hint,
        &.hint~.hint,
        &:-webkit-autofill~.hint {
            opacity: 0;
            top: 0;
        }
    }

    input.hint~.hint {
        opacity: 0;
        top: 0;
    }
}

/* Banners
========================================================================== */
.flex-row {
    @media(min-width:$bp-m) {
        display: flex;
        flex-wrap: nowrap;

        &>* {
            padding: 0;
            margin: 0;
            flex-grow: 1;
        }

        .banner {
            position: relative;
            height: 100%;
        }
    }
}

.banner {
    position: relative;
    padding: 40px 20px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    .content {
        padding: 35px;
        background: transparentize($color-white, .15);

        .title {
            margin: 0 0 10px;
            font-size: 3rem;
            font-weight: $fontWeight-semibold;
            line-height: 48px;
            color: $color-black;
        }
    }

    @media(min-width:$bp-m) {
        padding: 14% 5%;

        .content {
            width: 500px;
            max-width: 100%;
            margin: 0 auto;
        }
    }
}

/* Angled Block
========================================================================== */

.angled-block {
    margin-bottom: $grid-gutter-em*2;
}
.angled-block__link {
    display: block;
    color: $color-base;
    &:hover,&:focus {
        text-decoration: none;
        .angled-block__title {
            color: $color-primary;
        }
    }
}
.angled-block__image {
    background-position: center;
    background-size: cover;
    height: 325px;
    background-color: $color-gray-2;
}
.angled-block__content {
    position: relative;
    margin-bottom: 0;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: calc(100% - 30px);
        background: $color-gray-2;
        transition: all 0.3s ease-in-out;
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 30px;
        border-left: 30px solid $color-gray-2;
        border-bottom: 30px solid transparent;
    }
}
.angled-block__inner {
    width: calc(100% - 30px);
    padding: 30px 0 30px 30px;
    background: $color-gray-2;
}
.angled-block__title {
    margin: 0 0 10px;
    font-size: 1.25rem;
    font-weight: $fontWeight-semibold;
    line-height: normal;
    color: $color-base;
}

@media(min-width:$bp-l) {
    .angled-block__title {
        font-size: 1.5rem;
    }
}

/* Page Heading
========================================================================== */
.page-heading {
    position: relative;
    border-radius: 12px;
    z-index:1;
    padding:clamp(203px, 16.8vw, 324px) 16px clamp(40px, 9.6vw, 186px);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    &:before {
        @include pseudo();
        top:0;
        left:0;
        z-index:-1;
        width:100%;
        height:100%;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.10) 100%)
    }
}

.page-heading__media {
    position:absolute;
    top:0;
    left:0;
    z-index:-2;
    width:100%;
    height:100%;
    background-size:cover;
}

.page-heading__title {
    margin:0;
    font-family: $font-heading;
    font-size: clamp(48px, 3.75vw, 4.5rem);
    font-weight: $fontWeight-light;
    line-height: clamp(1.3em,  1.4vw, 1.7em);
    color:$color-white;
}

@media(min-width:$bp-l){
    .page-heading {
        border-radius: 24px;
    }
}