// Classification Dropdowns

/* Faux Dropdown
========================================================================== */

.faux-dropdown {
    position: relative;
    margin-bottom: 1em;
}
.faux-dropdown__inner {
	position: relative;
	display: inline-block;
}

.faux-dropdown__toggle {
	position: relative;
	padding: 15px 40px 15px 20px;
	border: 1px solid $color-gray-4;
	background: $color-white;
	font-size: .875rem;
	text-transform: uppercase;
	cursor: pointer;
	color: $color-gray-4;
	&:after {
		content: "";
		position: absolute;
		top: 50%;
		right: 1em;
		border: 6px solid transparent;
		border-top-color: $color-gray-4;
		transform: translateY(-30%);
		transition: transform 0.3s ease-in-out;
	}
	&.active:after {
		transform: translateY(-60%) rotate(180deg);
	}
}
.faux-dropdown__toggle--secondary {
	width: 100%;
	background: none;
	border: 0;
	position: relative;
	padding: 10px 35px 10px 10px;
	text-align: left;
	color: $color-base;
	text-transform: none;
	font-size: 1rem;
	&:hover,&:focus-visible,&.active {
		background: $color-primary;
		color: $color-white;
		&::after {
			border-top-color: $color-white;
		}
	}
}
.faux-dropdown__list {
	display: none;
	position: absolute;
	top: 100%;
	min-width: 100%;
	left: 0;
	z-index: 999;
	background: $color-white;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	list-style: none;
	margin: 0;
	padding: 0;
	white-space: nowrap;
}
.faux-dropdown__sub-list {
	display: none;
	list-style: none;
	margin: 0;
	padding: 0;
}
.faux-dropdown__item + .faux-dropdown__item {
	margin: 10px 0 0;
}
.faux-dropdown__link {
	display: block;
	padding: 10px;
	text-decoration: none;
	color: $color-base;
	&:hover,
	&:focus {
		background: $color-primary;
		text-decoration: none;
		color: $color-white;
	}
}