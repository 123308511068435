/* ================================================================
   Departments, Sub-departments and Product template styles
   ================================================================ */

/* Department Listing
========================================================================== */

.department-listing,
.subdepartment-listing {
    margin: 2em 0;

    ul.item-list {
        margin: 0;
        padding: 0;
        list-style-type: none;

        .item {
            border-top: 0;

            &+.item {
                margin-top: 0;
            }
        }
    }

    @media(min-width:$bp-m) {
        ul.item-list {
            display: flex;
            flex-wrap: wrap;
            margin-top:0;
            margin-bottom:0;
            margin-left:-$grid-gutter-em;
            margin-right:-$grid-gutter-em;

            .item {
                width: 50%;
                margin-bottom: $grid-gutter-em;
                padding: 0 $grid-gutter-em;

                .media {

                    background: #e7e7e7;

                }

                &+.item {
                    margin-top: 0;
                }
            }
        }
    }

    @media(min-width:$bp-l) {
        ul.item-list .item {

            width: 33.3333%;

        }
    }
}

.model-details,
.series-details,
.subdepartment-details {


    .model-item-details,
    .series-item-details,
    .subdepartment-item-details {
        padding-bottom: 30px;

        .title {
            margin: 0 0 10px;
            font-size: 1.5rem;
            font-weight: $fontWeight-bold;
            line-height: normal;
        }

        .media,
        .additional-links {
            margin-bottom: 30px;
        }

        .additional-links {
            li+li {
                margin-top: 10px;
            }

            a {
                position: relative;
                display: inline-block;
                padding-right: 20px;


                &:after {
                    content: "";
                    position: absolute;
                    top: 5px;

                    right: 0;
                    border: 5px solid transparent;
                    border-left-color: $color-primary;
                }
            }
        }

        .button {

            padding: .8em 1.5em;

            margin-bottom: 1em;

        }
    }

    @media(min-width:$bp-m) {


        .model-item-details,
        .series-item-details,
        .subdepartment-item-details {
            padding-bottom: 60px;

            .title {
                font-size: 3rem;
            }

            .media {

                margin-bottom: 60px;

            }

            .description {
                font-size: 1.125rem;
            }
        }
    }

    @media(min-width:$bp-mw) {

        .model-item-details .row,
        .series-item-details .row,
        .subdepartment-item-details .row {
            display: flex;
            align-items: flex-start;
        }
    }

    @media(min-width:$bp-l) {

        .model-item-details,
        .series-item-details,
        .subdepartment-item-details {
            background: url(/ResourcePackages/Talon/assets/dist/images/circles.png) no-repeat right 0;
        }
    }
}

.series-section {
    padding: 40px 0;
    border-top: 1px solid #ccc9c7;

    &>.title {
        margin: 0 0 30px;
        font-size: 1.5rem;
        font-weight: $fontWeight-semibold;
        line-height: normal;
    }

    table.series-table,
    table.model-table {
        &>thead th:first-child {
            border-left: 0;
        }

        &>thead th:last-child {
            border-right: 0;
        }
    }

    &>thead th:last-child {
        border-right: 0;
    }
    .dataTables_filter {
    
        display: none;
    
    }
    @media(min-width:$bp-l) {
        padding: 80px 0;
    
        &>.title {
            margin-bottom: 60px;
        }
    }
}

.series-item {
    .title {
        font-weight: $fontWeight-bold;
        color: $color-purple;

        a {
            text-decoration: none;
            color: $color-purple;

            &:hover,
            &:focus {
                text-decoration: underline;
                color: $color-purple;
            }
        }
    }

    .features ul {
        margin: 0;
        padding-left: 1em;

        li+li {
            margin-top: 10px;
        }
    }
}

table.series-table,
table.model-table,
table.tech-specs {
    width: 100%;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-collapse: collapse;
    background: $color-white;

    &>thead th {
        padding: 18px 18px 0;
        border: 0;
        border-left: 1px solid #ccc;
        font-size: 1.25rem;
        font-weight: $fontWeight-bold;
        text-align: left;
        vertical-align: bottom;

        &:first-child {
            border-left: 0;
        }

        &.sorting,
        &.sorting_asc,
        &.sorting_desc {
            background-position: center right;
        }
    }

    &>tbody td {
        padding: 18px;
        border: 0;
        border-left: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        vertical-align: top;
        font-size: .875rem;

        &:before {
            top: 20px !important;
        }
    }

    tbody>tr[role=row]>th:first-child:before,
    tbody>tr[role=row]>td:first-child:before {

        top: 50% !important;

        transform: translateY(-50%);

    }

    .dtr-details input {

        display: none;

    }

    .series-item .title,
    .model-item .title {
        margin: 0;
        padding: 0;
        font-size: 1rem;
    }

    .downloads svg {

        width: 15px;

        height: 15px;

        fill: $color-primary;

    }

    ul.document-list {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li+li {
            margin-top: 1em;
        }
    }

    @media(min-width:$bp-l) {
        &>thead th {
            padding: 36px 34px 0;
        }

        &>tbody td {
            padding: 36px 34px;

            &:before {
                top: 40px !important;
            }
        }

        ul.document-list li {
            display: inline-block;

            &+li {
                margin-bottom: 5px;
                margin-top: 0;

                &:before {
                    content: ",";
                    margin-right: 5px;
                }
            }
        }
    }

    input[type="text"] {
        height:40px;
        font-size: 1rem;
    }
}

table.model-table {
    &>thead th {
        padding: 15px 5px;
        border-bottom: 1px solid #ccc;
        background: $color-white;
        font-size: .875rem;
        color: $color-purple;

        .sort-button {
            width: 20px;
            height: 20px;
            margin: 0;
            padding: 0;
            border: 0;
        }
    }

    &>tbody td {
        padding: 12px;

        &:nth-child(1),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
            white-space: nowrap;
        }
    }

    &+.dataTables_info {}

    .dtr-title .sort-button {

        display: none;

    }
}

table.tech-specs {
    margin-top: 25px;
    border-top: 1px solid #ccc;

    thead th {
        background: $color-white;
    }

    svg {

        fill: $color-primary;

    }

    @media(min-width:$bp-m) {
        thead th {
            padding: 18px;
        }
    }

    @media(min-width:$bp-l) {
        thead th {

            padding: 36px 34px;

        }
    }

    @media(max-width:$bp-sw-max) {
        border-collapse: separate;

        thead th {

            display: none;

        }

        tbody tr {
            display: block;
            border-bottom: 1px solid #ccc;

            td {
                display: block;
                border: 0;
                padding: 10px 20px;

                &:before {
                    display: block;
                    margin-bottom: 10px;
                    font-size: 1.125rem;
                    font-weight: bold;
                    color: $color-black;
                }
            }

            td:first-child:before {

                content: "Number";

            }


            td:nth-child(2):before {

                content: "Title";

            }


            td:nth-child(3):before {

                content: "Downloads";

            }
        }
    }
}