/* ================================================================
   Page Meta Area
    - Page Tools/ Social Media
   ================================================================ */





/* Page Tools (Print | Email | Share)
   ================================================================ */

.share-buttons {
}
.share-buttons__list {
	list-style: none;
	margin: 0 auto;
	padding: 0;
}
.share-buttons__item {
	display: inline-block;
	margin: 0 20px 0 0;
	position: relative;
}
.share-buttons__link {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 42px;
	height: 42px;
	background: $color-white;
	border-radius: 50%;
	transition: .3s ease-in all;
	&:hover,&:focus {
		background: $color-base;
		.share-buttons__icon {
			fill: $color-white;
		}
	}
}
.share-buttons__link--facebook {
	background: $color-facebook;
}
.share-buttons__link--twitter {
	background: $color-twitter;
}
.share-buttons__link--email {
	background: $color-email;
}
.share-buttons__icon {
	width: 24px;
	height: 24px;
	fill: $color-white;
}

@include breakpoint(m) {
	.share-buttons {
		padding: 40px 15px;
	}
	.share-buttons__list {
		margin: 0;
	}
}