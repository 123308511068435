
.dataTable.collapsed {
    .model-table__toggle {
        display: inline-block;
    }
}
.model-table {
    scroll-behavior: smooth;
}

.model-table__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 10px;
}

.model-table__label {
    display: block;
    width: calc(100% - 35px);
    margin: 0 5px 0 0;
}

.model-table__sort {
    width: 30px;
    height: 40px;
    background: none;
    border: 0;
    padding: 0;
    position: relative;

    &::before,
    &::after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 10px 5px;
        border-color: transparent transparent #953b81 transparent;
        position: absolute;
        top: 7px;
        left: 0;
        right: 0;
        margin: auto;
        margin: auto;
    }

    &::after {
        border-width: 10px 5px 0 5px;
        border-color: #953b81 transparent transparent transparent;
        top: 19px;
    }

    .dtr-details & {
        display: none;
    }
    &:hover,&:focus {
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px transparentize($color-primary, .4);
    }
}

.model-table__sort--ascending {
    &::before {
        border-color: transparent transparent #2779ab transparent;
    }

    &::after {
        border-color: #953b81 transparent transparent transparent;
    }
}

.model-table__sort--descending {
    &::before {
        border-color: transparent transparent #953b81 transparent;
    }

    &::after {
        border-color: #2779ab transparent transparent transparent;
    }
}

.model-table__toggle {
    display: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    background: $color-purple;

    &[aria-expanded="true"] {
        &::after {
            transform: translateY(-50%) rotate(0);
        }
    }

    &::before,
    &::after {
        content: '';
        width: 12px;
        height: 2px;
        background: $color-white;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: auto;
    }

    &::after {
        transform: translateY(-50%) rotate(90deg);
    }

    &:hover,&:focus {
        box-shadow: 0 0 3px 1px $color-primary;
    }
}