/* ================================================================
   Variables
   ================================================================ */

// Color Variables
$color-white:#fff;
$color-black:#000;
$color-green: #40BC96;
$color-yellow: #F1C400;
$color-pink: #DB5966;
$color-purple:#933C91;
$color-blue:#3EA6E1;
$color-blue-dark:#187DB7;
$color-off-white:#E9ECF0;

$body-bg: #e9ecf0;
$color-base: #1B1B1C;
$color-primary: $color-blue;
$color-primary-dark: $color-blue-dark;
$color-secondary: $color-yellow;
$button-color:$color-primary;

$color-gray-1: #ccc9c7;
$color-gray-2: #E7E7E7;
$color-gray-3: #E9ECF0;
$color-gray-4: #757575;
$color-error: red;

$color-facebook: #4267B2;
$color-twitter: #00acee;
$color-email: gray;

$colors: (
    "primary": $color-primary,
    "secondary": $color-secondary,
    "dark-blue": $color-blue-dark,
    "pink": $color-pink,
    "purple": $color-purple,
    "yellow": $color-yellow
);

// Typography 
$font-primary: "Montserrat", sans-serif;
$font-secondary: "Work Sans", sans-serif;
$font-heading: $font-secondary;
 
$fontWeight-light: 300;
$fontWeight-normal: 400;
$fontWeight-medium: 500;
$fontWeight-semibold: 600;
$fontWeight-bold: 700;
 
$typography-margin-bottom: 1rem;

$image-path:"/ResourcePackages/Talon/assets/dist/images/";
 

// Breakpoints 
// You can convert these at http://pxtoem.com 
$bp-s: 20em; // 320px
$bp-s-max: 29.938em; // 479px
$bp-sw: 30em; // 480px
$bp-sw-max: 47.938em; // 767px
$bp-m: 48em; // 768px
$bp-m-max: 59.938em; // 959px
$bp-mw: 60em; // 960px
$bp-mw-max: 71.188em; // 1139px
$bp-l: 72em; // 1152px
$bp-l-max: 81.188em; // 1299px
$bp-xl: 81.25em; // 1300px
$bp-xxl: 100rem; // 1400px

$breakpoints: (
    xs: 0, 
    sm: $bp-s, 
    md: $bp-m,   
    lg: $bp-l, 
    xl: $bp-xl,
    xxl: $bp-xxl
);


// Navigation Variables
$nav-transition-speed: 0.4s;
$nav-barBackground: #333; 
$nav-mainLinkBackground: #666;  
$nav-subLinkBackground: #999; 
  

// Grid
$grid-container-width:93.75em; // 1500px
$grid-gutter: 12px; 
$grid-gutter-em: 0.75em; // Changing grid gutter to em causes a compiling error :/
